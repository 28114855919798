import { PageCondition } from "./conditions";
import ACTIONS from "./actions";
import goto from "./goto";
import { ReadonlyDeep } from "type-fest";
import { SessionStorageKey } from "src/utils/constants";

export enum SessionSurveyKey {
  patientIntake = SessionStorageKey.patientIntake,
  insuranceSurvey = SessionStorageKey.insuranceSurvey,
  sessionSurveyAnswers = SessionStorageKey.sessionSurveyAnswers,
}

export enum Direction {
  FORWARD = "FORWARD",
  BACKWARD = "BACKWARD",
}

export type MeristemClientConfig = JsonObject;

export type PageListEntry = Block | Survey | Page | SwitchCondition;
export type PageList = ReadonlyArray<PageListEntry>;

export type PageSet = {
  readonly ["//"]?: string;
  readonly id: string;
  readonly description?: string;
  readonly pages: PageList;
  readonly layer: string;
  readonly conditions?: ReadonlyArray<PageCondition>;
  readonly props?: ReadonlyDeep<PropertyBag>;
};

/** @notExported */
type InheritablePageParams = {
  readonly ["//"]?: string;

  readonly entryPoints?: ReadonlyArray<string>;
  readonly conditions?: ReadonlyArray<PageCondition>;
  readonly actions?: ReadonlyArray<Action>;
  // Hook that triggers when this block is passed over, ie conditions not met
  readonly onSkip?: ReadonlyArray<Action>;
  readonly props?: ReadonlyDeep<PropertyBag> & {
    /**
     * Numeric index of the progress bar this component is part of, if displayed.
     *
     * - 1 | 2 | 3 -> Progress bar section
     * - false -> No progress bar
     * - true -> Use relative position for progress bar section
     */
    readonly progressBar?: number | boolean;
  };
};

/** @notExported */
type GoToActions = { readonly ["//"]?: string } & {
  readonly [name in keyof typeof goto]?: ReadonlyDeep<
    Parameters<typeof goto[name]>
  >;
};

export type Block = {
  readonly block: PageList;

  // Hook that triggers at the exit of the block
  readonly onComplete?: ReadonlyArray<Action>;
} & InheritablePageParams;

export type Survey = Block & {
  readonly name: string;
  readonly surveyNameSpace:
    | "mainSurvey"
    | "telehealthFeedbackSurveyAnswers"
    | `userData.${string}`;

  // Any question that uses session storage will save its answers under this key
  readonly sessionSurveyKey?: SessionSurveyKey;
  readonly loggedInHeader?: boolean;
  readonly hideHeader?: boolean;
  readonly titleOverride?: string;
  readonly skipSeen?: "prior-layer" | false;

  // Hook that triggers for after each page in block
  readonly onAnswered?: ReadonlyArray<Action>;
  // Indicates if the current survey answers answers should be combined with another survey
  // for progress bar purposes
  // NOTE(kevinh): For the current implementation of combineProgress, the pageset that is defining
  // the combineProgress field must come BEFORE the pageset that it will combine progress with. For
  // example, if we are combining progress for landing survey and main survey, then the landing
  // survey pageset must receive the combineProgress property due to landing coming sequentially before
  // main survey.
  readonly combineProgress?: string;
} & InheritablePageParams;

export type Page = {
  readonly pathname?: string;
  /**
   * Array of alternative react-router paths that can match this particular entry.
   * gotoNextPage will not actively navigate to these pages, but direct
   * navigation to these urls will still be considered within the pageset.
   *
   * Only used when pathname is defined. This is generally intended for managing
   * legacy urls that are not redirected.
   */
  readonly aliases?: ReadonlyArray<string>;

  readonly goto?: GoToActions;

  /**
   * Indicates that we expect the user to return after this goto
   * operation and we should not run any onComplete, etc actions.
   * This prevents onComplete actions from running in response to
   * goto operations.
   */
  readonly continueBlock?: boolean;
} & InheritablePageParams;

export type SwitchCondition = {
  readonly switch: string;
  readonly cases: {
    readonly [key: string]: Page;
    readonly default?: Page;
  };
} & InheritablePageParams;

export interface Action {
  readonly type: keyof typeof ACTIONS;
  readonly params?: ReadonlyDeep<PropertyBag>;
}
