import { onCompletePaymentSurvey } from "@pageDefinitions/actions/survey/onCompletePaymentSurvey";
import { onCompleteEmployerSurvey } from "@pageDefinitions/actions/survey/onCompleteEmployerSurvey";
import { onStartMainSurvey } from "@pageDefinitions/actions/survey/onStartMainSurvey";
import { onCompleteMainSurvey } from "@pageDefinitions/actions/survey/onCompleteMainSurvey";
import { onCompleteLandingPageSurvey } from "@pageDefinitions/actions/survey/onCompleteLandingPageSurvey";
import {
  mixpanelTrackAction,
  noomEventTrackAction,
} from "@pageDefinitions/actions/tracking";

import { GoToPageOptions } from "../goto/pageSet";
import { PageListEntry, PageSet } from "../types";
import { onStartSurvey } from "./survey/onStartSurvey";
import { saveAnswers, saveNoomPremiumAnswers } from "./survey/saveAnswers";
import { SaveEventToBraze } from "./saveToBraze";
import { saveToSession } from "./session";
import { onCompleteTelehealthFeedbackSurvey } from "@pageDefinitions/actions/survey/onCompleteTelehealthFeedbackSurvey";
import { onStartTelehealthFeedbackSurvey } from "@pageDefinitions/actions/survey/onStartTelehealthFeedbackSurvey";
import { trackVibeOfferSuppressed } from "./survey/trackVibeOfferSuppressed";
import { trackVibeOfferViewed } from "./survey/trackVibeOfferViewed";
import { setZumbaWhereDidYouHear } from "./survey/setZumbaWhereDidYouHear";
import { trackCurrentTierEvent } from "@utils/counterOffer";
import { saveRecommendedPlanToCRM } from "./survey/saveRecommendedPlanToCRM";

export type ActionHandlerParams = {
  params: JsonObject;
  pageSet: PageSet;
  page: PageListEntry;
} & GoToPageOptions;

export type ActionHandler = (
  params: ActionHandlerParams
) => void | Promise<void>;

const ACTIONS = {
  TRACK: mixpanelTrackAction,
  TRACK_BUYFLOW: noomEventTrackAction,
  SAVE_EVENT_TO_BRAZE: SaveEventToBraze,

  SAVE_TO_SESSION: saveToSession,

  SAVE_ANSWERS: saveAnswers,
  SAVE_NOOM_PREMIUM_ANSWERS: saveNoomPremiumAnswers,

  SAVE_RECOMMENDED_PLAN_TO_CRM: saveRecommendedPlanToCRM,

  // Generic Survey
  ON_START_SURVEY: onStartSurvey,

  // Main Survey
  ON_START_MAIN_SURVEY: onStartMainSurvey,
  ON_COMPLETE_MAIN_SURVEY: onCompleteMainSurvey,

  // Payment Survey
  ON_COMPLETE_PAYMENT_SURVEY: onCompletePaymentSurvey,

  // Employer Survey
  ON_COMPLETE_EMPLOYER_SURVEY: onCompleteEmployerSurvey,

  // Telehealth Feedback Survey
  ON_START_TELEHEALTH_FEEDBACK_SURVEY: onStartTelehealthFeedbackSurvey,
  ON_COMPLETE_TELEHEALTH_FEEDBACK_SURVEY: onCompleteTelehealthFeedbackSurvey,

  // Landing page Survey
  ON_COMPLETE_LANDING_PAGE_SURVEY: onCompleteLandingPageSurvey,

  SET_ZUMBA_WHERE_DID_YOU_HEAR: setZumbaWhereDidYouHear,

  TRACK_VIBE_OFFER_VIEWED: trackVibeOfferViewed,
  TRACK_VIBE_OFFER_SUPPRESSED: trackVibeOfferSuppressed,

  TRACK_CURRENT_TIER_EVENT: trackCurrentTierEvent,
};

export default ACTIONS;
