import React, {
  forwardRef,
  ForwardedRef,
  InputHTMLAttributes,
  ReactNode,
  useState,
  HTMLAttributes,
} from "react";
import styled from "@emotion/styled";
import { Interpolation } from "@emotion/react";
import { Field, inputBoxStyles } from "../Field";
import { useGeneratedErrorMessageId } from "src/components/error/ErrorMessage";

export type TextInputProps = {
  "data-cy"?: string;
  errorMessage?: ReactNode;
  hasError?: boolean;
  announceError?: boolean;
  label: ReactNode;
  labelProps?: HTMLAttributes<HTMLLabelElement>;
  labelStyle: "hidden" | "above";
  suffixElement?: ReactNode;
  isSuffixInteractive?: boolean;
  showErrorOverride?: boolean;
  type?: "text" | "email" | "number" | "password";
  forceReadOnly?: boolean;
  _container?: Interpolation<any>;
  _label?: Interpolation<any>;
  _error?: Interpolation<any>;
} & InputHTMLAttributes<HTMLInputElement>;

const Input = styled.input<{ hasError: boolean }>`
  ${inputBoxStyles}
`;

// Container that a suffix can be absolutely positioned within
const InputWithSuffixContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
`;

const SuffixContainer = styled.div<{ interactive: boolean }>`
  margin: auto 18px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  font-size: 18px;

  // Allow the suffix to be clicked through to activate the input if it's non-interactive
  ${(props) => !props.interactive && "pointer-events: none"}
`;

const TextInput = forwardRef(
  (
    {
      "data-cy": dataCy,
      errorMessage,
      hasError = false,
      announceError = true,
      label,
      labelStyle = "hidden",
      suffixElement,
      isSuffixInteractive,
      showErrorOverride, // This should be passed in if the calling component wants to control its own showError state
      type = "text",
      forceReadOnly = false,
      _container,
      _label,
      _error,
      labelProps,
      ...inputProps
    }: TextInputProps,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    // If showErrorOverride is not passed in, then we will by default only show errors after the user has
    // attempted to input something for the first time and then clicks away. After this, the error message
    // will always show when applicable.
    const [showError, setShowError] = useState(!!inputProps.value);

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
      inputProps.onBlur?.(e);
      setShowError(true);
    };

    const displayError =
      hasError &&
      (typeof showErrorOverride === "boolean" ? showErrorOverride : showError);

    const errorId = useGeneratedErrorMessageId();

    return (
      <Field
        label={label}
        labelStyle={labelStyle}
        hasError={displayError}
        errorMessage={errorMessage}
        _container={_container}
        _label={_label}
        _error={_error}
        labelProps={labelProps}
        announceError={announceError}
        errorId={errorId}
      >
        <InputWithSuffixContainer>
          <Input
            ref={ref}
            data-cy={dataCy}
            hasError={displayError}
            type={type}
            {...inputProps}
            onBlur={handleBlur}
            // Force input to be read only. Needed to override react-datepicker co-opting
            // this prop, so we can prevent keyboard input on our date picker.
            {...(forceReadOnly && { readOnly: true })}
            aria-invalid={displayError}
            // Prevents a double announcement on NVDA chrome
            aria-describedby={announceError ? "" : errorId}
          />
          {suffixElement && (
            <SuffixContainer interactive={isSuffixInteractive}>
              {suffixElement}
            </SuffixContainer>
          )}
        </InputWithSuffixContainer>
      </Field>
    );
  }
);

export default TextInput;
