import { getFeatureFlagValue } from "src/pageDefinitions/featureFlags";

export const clinicalPlans = {
  branded: "telehealth-medtier-branded",
  compounded: "telehealth-medtier-compounded",
};

export default {
  "1": "option_d_20221201_Ex1084var1email",
  "2": "option_f_20221201_Ex1084var1email",
  "3": "option_m_20221201_Ex1084var1email",
  "4": "option_e_20221201_Ex1084var1email",
  "5": "option_n_20221201_Ex1084var1email",
  "6": "option_g_20221201_Ex1084var1email",
  "7": "option_o_20221201_Ex1084var1email",
  "8": "option_h_20221201_Ex1084var1email",
  "9": "option_i_20221201_Ex1084var1email",
  "10": "option_k_20221201_Ex1084var1email",
  "11": "option_l_20221201_Ex1084var1email",
  "12": "option_j_20221201_Ex1084var1email",
  "2-intlex114": "option_f_20210814_ex114",
  "4-intlex114": "option_e_20210814_ex114",
  "6-intlex114": "option_g_20210814_ex114",
  "8-intlex114": "option_h_20210814_ex114",
  "1-7-DAY-TRIAL": "option_d_20221222_Ex1084var1",
  "2-7-DAY-TRIAL": "option_f_20221201_Ex1084var1",
  "3-7-DAY-TRIAL": "option_m_20221201_Ex1084var1",
  "4-7-DAY-TRIAL": "option_e_20221201_Ex1084var1",
  "5-7-DAY-TRIAL": "option_n_20221201_Ex1084var1",
  "6-7-DAY-TRIAL": "option_g_20221201_Ex1084var1",
  "7-7-DAY-TRIAL": "option_o_20221201_Ex1084var1",
  "8-7-DAY-TRIAL": "option_h_20221201_Ex1084var1",
  "9-7-DAY-TRIAL": "option_i_20221201_Ex1084var1",
  "10-7-DAY-TRIAL": "option_k_20221201_Ex1084var1",
  "11-7-DAY-TRIAL": "option_l_20221201_Ex1084var1",
  "12-7-DAY-TRIAL": "option_j_20221201_Ex1084var1",
  "2-30-DAY-TRIAL": "option_f_30d_20240612",
  "3-30-DAY-TRIAL": "option_m_30d_20240612",
  "4-30-DAY-TRIAL": "option_e_30d_20240612",
  "5-30-DAY-TRIAL": "option_n_30d_20240612",
  "6-30-DAY-TRIAL": "option_g_30d_20240612",
  "7-30-DAY-TRIAL": "option_o_30d_20240612",
  "8-30-DAY-TRIAL": "option_h_30d_20240612",
  "9-30-DAY-TRIAL": "option_i_30d_20240612",
  "10-30-DAY-TRIAL": "option_k_30d_20240612",
  "11-30-DAY-TRIAL": "option_l_30d_20240612",
  "12-30-DAY-TRIAL": "option_j_30d_20240612",
  "2-IN-APP": "option_f_20221201_Ex1084var1app",
  "3-IN-APP": "option_m_20221201_Ex1084var1app",
  "4-IN-APP": "option_e_20221201_Ex1084var1app",
  "5-IN-APP": "option_n_20221201_Ex1084var1app",
  "6-IN-APP": "option_g_20221201_Ex1084var1app",
  "7-IN-APP": "option_o_20221201_Ex1084var1app",
  "8-IN-APP": "option_h_20221201_Ex1084var1app",
  "9-IN-APP": "option_i_20221201_Ex1084var1app",
  "10-IN-APP": "option_k_20221201_Ex1084var1app",
  "11-IN-APP": "option_l_20221201_Ex1084var1app",
  "12-IN-APP": "option_j_20221201_Ex1084var1app",
  "4-exw18": "option_e_20201203_exw18",
  "6-exw18": "option_g_20201203_exw18",
  "7-exw18": "option_o_20201203_exw18",
  "3-exw23b": "option_m_20210204_exw23b",
  "4-exw23b": "option_e_20210204_exw23b",
  "5-exw23b": "option_n_20210204_exw23b",
  "6-exw23b": "option_g_20210204_exw23b",
  "7-exw23b": "option_o_20210204_exw23b",
  "8-exw23b": "option_h_20210204_exw23b",
  "2-ex561": "option_f_20201112_ex561",
  "3-ex561": "option_m_20201112_ex561",
  "4-ex561": "option_e_20201112_ex561",
  "5-ex561": "option_n_20201112_ex561",
  "6-ex561": "option_g_20201112_ex561",
  "7-ex561": "option_o_20201112_ex561",
  "8-ex561": "option_h_20201112_ex561",
  "2-7-DAY-TRIAL-ex561": "option_f_20201112_ex561_7d",
  "3-7-DAY-TRIAL-ex561": "option_m_20201112_ex561_7d",
  "4-7-DAY-TRIAL-ex561": "option_e_20201112_ex561_7d",
  "5-7-DAY-TRIAL-ex561": "option_n_20201112_ex561_7d",
  "6-7-DAY-TRIAL-ex561": "option_g_20201112_ex561_7d",
  "7-7-DAY-TRIAL-ex561": "option_o_20201112_ex561_7d",
  "8-7-DAY-TRIAL-ex561": "option_h_20201112_ex561_7d",
  "2-ex443": "option_e_20200513_ex443",
  "3-ex443": "option_f_20200513_ex443",
  "4-ex443": "option_g_20200513_ex443",
  "5-ex443": "option_h_20200513_ex443",
  "6-ex443": "option_i_20200513_ex443",
  "7-ex443": "option_j_20200513_ex443",
  "8-ex443": "option_k_20200513_ex443",
  "4-flat-39": "option_108a",
  "4-flat-34": "option_108b",
  "2-nice": "option_108c_2",
  "6-nice": "option_108c_6",
  "8-nice": "option_108c_8",
  "4_99": "option_e_20161031_99",
  "6_99": "option_g_20170428_99",
  "8_99": "option_h_20170428_99",
  "2_JA_extreme": "option_i_20181214",
  "4_JA_extreme": "option_j_20181214",
  "6_JA_extreme": "option_k_20181214",
  "1-4499": "option_l_20190314",
  "1-IN-APP": "option_d_20221222_Ex1084var1app",
  "2-intlEx10-eur": "option_f_20200107_ie10_eur",
  "4-exj120": "option_e_20200508_exj120",
  "6-exj120": "option_f_20200508_exj120",
  "8-exj120": "option_g_20200508_exj120",
  "4-exj122": "option_e_20200611_exj122",
  "6-exj122": "option_g_20200611_exj122",
  "8-exj122": "option_h_20200611_exj122",
  "1-intlex27v1": "option_d_20200529_intlex27v1",
  "12-intlex27v1": "option_j_20200529_intlex27v1",
  "1-intlex27v2": "option_d_20200529_intlex27v2",
  "6-intlex27v2": "option_g_20200529_intlex27v2",
  "8-intlex27v2": "option_h_20200529_intlex27v2",
  "5-ex427v1": "option_n_20200601_ex427v1",
  "6-ex427v1": "option_g_20200601_ex427v1",
  "8-ex427v1": "option_h_20200601_ex427v1",
  "4-ex427v2": "option_e_20200601_ex427v2",
  "6-ex427v2": "option_g_20200601_ex427v2",
  "7-ex427v2": "option_o_20200601_ex427v2",
  "1-sxen122": "option_d_20161031_sxen122",
  "2-sxen122": "option_f_20170320_sxen122",
  "3-sxen122": "option_m_20200204_sxen122",
  "4-sxen122": "option_e_20200417_sxen122",
  "5-sxen122": "option_n_20200204_sxen122",
  "6-sxen122": "option_g_20200417_sxen122",
  "7-sxen122": "option_o_20200417_sxen122",
  "8-sxen122": "option_h_20200417_sxen122",
  "12-sxen122": "option_j_20171222_sxen122",
  "5-ex454v1": "option_n_20200603_ex454v1",
  "6-ex454v1": "option_g_20200603_ex454v1",
  "5-ex454v2": "option_n_20200603_ex454v2",
  "6-ex454v2": "option_g_20200603_ex454v2",
  "2-ex465v3": "option_f_20200623_ex465v3",
  "3-ex465v3": "option_m_20200623_ex465v3",
  "4-ex465v3": "option_e_20200623_ex465v3",
  "5-ex465v3": "option_n_20200623_ex465v3",
  "6-ex465v3": "option_g_20200623_ex465v3",
  "7-ex465v3": "option_o_20200623_ex465v3",
  "8-ex465v3": "option_h_20200623_ex465v3",
  "2-ex465v4": "option_f_20200623_ex465v4",
  "3-ex465v4": "option_m_20200623_ex465v4",
  "4-ex465v4": "option_e_20200623_ex465v4",
  "5-ex465v4": "option_n_20200623_ex465v4",
  "6-ex465v4": "option_g_20200623_ex465v4",
  "7-ex465v4": "option_o_20200623_ex465v4",
  "8-ex465v4": "option_h_20200623_ex465v4",
  "2-ex465v5": "option_f_20200603_ex465v5",
  "3-ex465v5": "option_m_20200603_ex465v5",
  "4-ex465v5": "option_e_20200603_ex465v5",
  "5-ex465v5": "option_n_20200603_ex465v5",
  "6-ex465v5": "option_g_20200603_ex465v5",
  "7-ex465v5": "option_o_20200603_ex465v5",
  "8-ex465v5": "option_h_20200603_ex465v5",
  "1-exj13": "option_d_20200819_exj13",
  "5-ex495v1": "option_n_20200603_ex495v1",
  "6-ex495v1": "option_g_20200603_ex495v1",
  "3-hm-v1": "option_m_20200827_v1",
  "3-hm-v2": "option_m_20200827_v2",
  "4-hm": "option_e_20201007",
  "2-ex521": "option_f_20200603_ex521",
  "3-ex521": "option_m_20200603_ex521",
  "4-ex521": "option_e_20200603_ex521",
  "5-ex521": "option_n_20200603_ex521",
  "6-ex521": "option_g_20200603_ex521",
  "7-ex521": "option_o_20200603_ex521",
  "8-ex521": "option_h_20200603_ex521",
  "2-ex524-25q": "option_f_20201006_ex524_25q",
  "3-ex524-25q": "option_m_20201006_ex524_25q",
  "4-ex524-25q": "option_e_20201006_ex524_25q",
  "5-ex524-25q": "option_n_20201006_ex524_25q",
  "6-ex524-25q": "option_g_20201006_ex524_25q",
  "7-ex524-25q": "option_o_20201006_ex524_25q",
  "8-ex524-25q": "option_h_20201006_ex524_25q",
  "2-ex524-75q": "option_f_20201006_ex524_75q",
  "3-ex524-75q": "option_m_20201006_ex524_75q",
  "4-ex524-75q": "option_e_20201006_ex524_75q",
  "5-ex524-75q": "option_n_20201006_ex524_75q",
  "6-ex524-75q": "option_g_20201006_ex524_75q",
  "7-ex524-75q": "option_o_20201006_ex524_75q",
  "8-ex524-75q": "option_h_20201006_ex524_75q",
  "2-ex524-100q": "option_f_20201006_ex524_100q",
  "3-ex524-100q": "option_m_20201006_ex524_100q",
  "4-ex524-100q": "option_e_20201006_ex524_100q",
  "5-ex524-100q": "option_n_20201006_ex524_100q",
  "6-ex524-100q": "option_g_20201006_ex524_100q",
  "7-ex524-100q": "option_o_20201006_ex524_100q",
  "8-ex524-100q": "option_h_20201006_ex524_100q",
  "2-intlex134": "option_f_20201012_intlex134",
  "4-intlex134": "option_e_20201012_intlex134",
  "6-intlex134": "option_g_20201012_intlex134",
  "8-intlex134": "option_h_20201012_intlex134",
  "3-ex536v1": "option_m_20201016_ex536v1",
  "4-ex536v1": "option_e_20201016_ex536v1",
  "3-ex536v2": "option_m_20201016_ex536v2",
  "4-ex536v2": "option_e_20201016_ex536v2",
  "5-ex536v2": "option_n_20201016_ex536v2",
  "8-ex536v2": "option_h_20201016_ex536v2",
  "2-intlex59v1": "option_f_20201012_intlex59v1",
  "2-intlex59v2": "option_f_20201012_intlex59v2",
  "8-intlex59v2": "option_h_20201012_intlex59v2",
  "2-intlex96": "option_f_20210408_intlex96_7d",
  "4-intlex96": "option_e_20210408_intlex96_7d",
  "6-intlex96": "option_g_20210408_intlex96_7d",
  "8-intlex96": "option_h_20210408_intlex96_7d",
  "3-ex566": "option_m_20201129_ex566",
  "4-ex566": "option_e_20201129_ex566",
  "4-hm-ex-22-v1": "option_e_20210128_v1",
  "4-hm-ex-22-v2": "option_e_20210128_v2",
  "6-hm-ex-50": "option_g_20210622",
  "3-hm-ex-62-v1": "option_m_20210805_v1",
  "3-hm-ex-62-v2": "option_m_20210805_v2",
  "2-exc293": "option_f_20210202",
  "3-exc293": "option_m_20210202",
  "4-exc293": "option_e_20210202",
  "5-exc293": "option_n_20210202",
  "6-exc293": "option_g_20210202",
  "7-exc293": "option_o_20210202",
  "8-exc293": "option_h_20210202",
  "12-exc293": "option_j_20210202",
  "4-ex686v1": "option_e_20210527_ex686_v1",
  "5-ex686v1": "option_n_20210611_ex686_2_v1",
  "6-ex686v1": "option_g_20210611_ex686_2_v1",
  "7-ex686v1": "option_o_20210527_ex686_v1",
  "8-ex686v1": "option_h_20210527_ex686_v1",
  "9-ex686v1": "option_i_20210611_ex686_2_v1",
  "10-ex686v1": "option_k_20210611_ex686_2_v1",
  "3-ex686v2": "option_m_20210527_ex686_v2",
  "4-ex686v2": "option_e_20210527_ex686_v2",
  "5-ex686v2": "option_n_20210611_ex686_2_v2",
  "6-ex686v2": "option_g_20210611_ex686_2_v2",
  "7-ex686v2": "option_o_20210527_ex686_v2",
  "8-ex686v2": "option_h_20210611_ex686_2_v2",
  "9-ex686v2": "option_i_20210611_ex686_2_v2",
  "2-ex716v2": "option_f_20210625_ex716_7d",
  "3-ex716v2": "option_m_20210625_ex716_7d",
  "4-ex716v2": "option_e_20210625_ex716_7d",
  "5-ex716v2": "option_n_20210625_ex716_7d",
  "6-ex716v2": "option_g_20210625_ex716_7d",
  "7-ex716v2": "option_o_20210625_ex716_7d",
  "8-ex716v2": "option_h_20210625_ex716_7d",
  "4-krex140": "option_e_20201215_ex140_7d",
  "6-krex140": "option_g_20201215_ex140_7d",
  "2-krex141v1": "option_f_20201215_ex141v1_7d",
  "4-krex141v1": "option_e_20201215_ex141v1_7d",
  "6-krex141v1": "option_g_20201215_ex141v1_7d",
  "4-krex141v2": "option_e_20201215_ex141v2_7d",
  "6-krex141v2": "option_g_20201215_ex141v2_7d",
  "2-krex141v3": "option_f_20201215_ex141v3_7d",
  "4-krex141v3": "option_e_20201215_ex141v3_7d",
  "6-krex141v3": "option_g_20201215_ex141v3_7d",
  "2-krex151": "option_f_20201215_ex151_7d",
  "4-krex151": "option_e_20201215_ex151_7d",
  "6-krex151": "option_g_20201215_ex151_7d",
  "1-krex149": "option_d_20210324_ex149_14d",
  "2-krex149": "option_f_20210324_ex149_14d",
  "4-krex149": "option_e_20210324_ex149_14d",
  "6-krex149": "option_g_20210324_ex149_14d",
  "1-kr-baseline": "option_d_20210413_baseline_14d",
  "2-kr-baseline": "option_f_20210413_baseline_14d",
  "4-kr-baseline": "option_e_20210413_baseline_14d",
  "6-kr-baseline": "option_g_20210413_baseline_14d",
  "2-betterhealth": "option_f_20201012_better_health",
  "3-betterhealth": "option_m_20211215_better_health",
  "4-betterhealth": "option_e_20201012_better_health",
  "5-betterhealth": "option_n_20211215_better_health",
  "6-betterhealth": "option_g_20201012_better_health",
  "7-betterhealth": "option_o_20211215_better_health",
  "8-betterhealth": "option_h_20201012_better_health",
  "9-betterhealth": "option_i_20211215_better_health",
  "10-betterhealth": "option_k_20211215_better_health",
  "11-betterhealth": "option_l_20211215_better_health",
  "12-betterhealth": "option_j_20211215_better_health",
  "2-exintl86": "option_f_20210303_intlex60",
  "4-exintl86": "option_e_20210303_intlex60",
  "6-exintl86": "option_g_20210303_intlex60",
  "8-exintl86": "option_h_20210303_intlex60",
  "7-ex665v1": "option_o_20210517_ex665_v1",
  "2-intlex110": "option_f_20210509_intlex110",
  "4-intlex110": "option_e_20210509_intlex110",
  "6-intlex110": "option_g_20210509_intlex110",
  "8-intlex110": "option_h_20210509_intlex110",
  "2-intlex127": "option_f_20210615_intlex127",
  "4-intlex127": "option_e_20210615_intlex127",
  "6-intlex127": "option_g_20210615_intlex127",
  "8-intlex127": "option_h_20210615_intlex127",
  "3-upsex116": "option_m_20210616_upsex116",
  "4-upsex116": "option_e_20210616_upsex116",
  "5-upsex116": "option_n_20210616_upsex116",
  "6-upsex116": "option_g_20210616_upsex116",
  "7-upsex116": "option_o_20210616_upsex116",
  "8-upsex116": "option_h_20210616_upsex116",
  "9-upsex116": "option_i_20210616_upsex116",
  "4-research30": "option_e_20210716_30",
  "4-research60": "option_e_20210716_60",
  "2-intlex143": "option_f_20210830_ex143",
  "4-intlex143": "option_e_20210830_ex143",
  "6-intlex143": "option_g_20210830_ex143",
  "8-intlex143": "option_h_20210830_ex143",
  "1-sxen392": "option_d_20211027_sxen392",
  bootcamp_discount: "option_m_20171228",
  "1-4499-notrial": "option_l_20190524",
  "dpp-12-month": "option_p_20190815_dpp",
  "2-affiliate": "option_f_20221205_Ex1084var1",
  "3-affiliate": "option_m_20221205_Ex1084var1",
  "4-affiliate": "option_e_20221205_Ex1084var1",
  "5-affiliate": "option_n_20221205_Ex1084var1",
  "6-affiliate": "option_g_20221205_Ex1084var1",
  "7-affiliate": "option_o_20221205_Ex1084var1",
  "8-affiliate": "option_h_20221205_Ex1084var1",
  "9-affiliate": "option_i_20221205_Ex1084var1",
  "10-affiliate": "option_k_20221205_Ex1084var1",
  "11-affiliate": "option_l_20221205_Ex1084var1",
  "12-affiliate": "option_j_20221205_Ex1084var1",
  "4-hm-7dt": "option_e_20220602",
  "1-no-trial": "option_d_20240410_ex1227",
  "2-no-trial": "option_f_20240410_ex1227",
  "3-no-trial": "option_m_20240410_ex1227",
  "4-no-trial": "option_e_20240410_ex1227",
  "5-no-trial": "option_n_20240410_ex1227",
  "6-no-trial": "option_g_20240410_ex1227",
  "7-no-trial": "option_o_20240410_ex1227",
  "8-no-trial": "option_h_20240410_ex1227",
  "9-no-trial": "option_i_20240410_ex1227",
  "10-no-trial": "option_k_20240410_ex1227",
  "11-no-trial": "option_l_20240410_ex1227",
  "12-no-trial": "option_j_20240410_ex1227",
  [clinicalPlans.branded]: "option_m_30d_20240710",
  [clinicalPlans.compounded]: "option_m_21d_20240710",
};

// NOTE: If changing this object, be mindful of the function "isTelehealthPlan"
// which is used in numerous other places
export const planDurationMonthsToTelehealthNoomPlanId = {
  2: "option_f_20221117",
  3: "option_m_20221117",
  4: "option_e_20221117",
  5: "option_n_20221117",
  6: "option_g_20221117",
  7: "option_o_20221117",
  8: "option_h_20221117",
  9: "option_i_20221117",
  10: "option_k_20221117",
  11: "option_l_20221117",
  12: "option_j_20221117",
};

const planDurationMonthsTo7DayTrialMultiUserNoomPlanId = {
  1: "option_d_20230510",
  2: "option_f_20230228",
  3: "option_m_20230228",
  4: "option_e_20230228",
  5: "option_n_20230228",
  6: "option_g_20230228",
  7: "option_o_20230228",
  8: "option_h_20230228",
  9: "option_i_20230228",
  10: "option_k_20230228",
  11: "option_l_20230228",
  12: "option_j_20230228",
};

const planDurationMonthsTo14DayTrialMultiUserNoomPlanId = {
  1: "option_d_20230511",
  2: "option_f_20230511",
  3: "option_m_20230511",
  4: "option_e_20230511",
  5: "option_n_20230511",
  6: "option_g_20230511",
  7: "option_o_20230511",
  8: "option_h_20230511",
  9: "option_i_20230511",
  10: "option_k_20230511",
  11: "option_l_20230511",
  12: "option_j_20230511",
};

const expR143PlanDurationMonthsTo7DayTrialMultiUserNoomPlanId = {
  1: "option_d_20230713_exr143",
  2: "option_f_20230713_exr143",
  3: "option_m_20230713_exr143",
  4: "option_e_20230713_exr143",
  5: "option_n_20230713_exr143",
  6: "option_g_20230713_exr143",
  7: "option_o_20230713_exr143",
  8: "option_h_20230713_exr143",
  9: "option_i_20230713_exr143",
  10: "option_k_20230713_exr143",
  11: "option_l_20230713_exr143",
  12: "option_j_20230713_exr143",
};

const expR143PlanDurationMonthsTo14DayTrialMultiUserNoomPlanId = {
  1: "option_d_20230713_exr143_14d",
  2: "option_f_20230713_exr143_14d",
  3: "option_m_20230713_exr143_14d",
  4: "option_e_20230713_exr143_14d",
  5: "option_n_20230713_exr143_14d",
  6: "option_g_20230713_exr143_14d",
  7: "option_o_20230713_exr143_14d",
  8: "option_h_20230713_exr143_14d",
  9: "option_i_20230713_exr143_14d",
  10: "option_k_20230713_exr143_14d",
  11: "option_l_20230713_exr143_14d",
  12: "option_j_20230713_exr143_14d",
};

// Fallback to 7 day trial durations as the default.
// For plan switches, the trial duration doesn't matter,
// If a user signed up with a 14 day trial and switch on the 4th day to the 7 day trial plan
// their new trial will be 14-4=10 days
export const getMultiUserNoomPlanId = (planLength, trialDuration = 7) => {
  if (getFeatureFlagValue("useBaselinePriceForMultiUserPlan")) {
    if (trialDuration === 14) {
      return expR143PlanDurationMonthsTo14DayTrialMultiUserNoomPlanId[
        planLength
      ];
    }
    return expR143PlanDurationMonthsTo7DayTrialMultiUserNoomPlanId[planLength];
  }
  if (trialDuration === 14) {
    return planDurationMonthsTo14DayTrialMultiUserNoomPlanId[planLength];
  }
  return planDurationMonthsTo7DayTrialMultiUserNoomPlanId[planLength];
};
