import { JsonObject } from "type-fest";
import { Recipe } from "src/funnels/custom-plans/types";
import { ShippingInfo, ShippingInfoWithName } from "src/funnels/offers/models";
import { trackEvent } from "src/utils/api/tracker";
import { send } from "src/utils/fetch";
import { getLanguage } from "src/utils/meristemContext";
import getStore from "src/utils/redux/store";
import { isInApp, SegmentFunction } from "src/utils/userSegment";
import { isDevMode } from "src/utils/userSegment/features";
import { Offer } from "src/utils/redux/slices/serverContext";
import { appConfig } from "src/config";
import { getAccessToken, getWebAuthCookie } from "@utils/authCookieParser";

const SUMMIT_LOCALSTORAGE_KEY = "summit_ineligible";

type DiscountResponse = {
  amount: number;
};

export type Product = {
  productId: string;
  price: number;
  isNotChargeable: boolean;
  isPhysicalProduct: boolean;
};

type PurchaseParams = {
  userId: string;
  email: string;
  currencySymbol: string;
  productIds: string[];
  shouldValidateAccessToken: boolean;
  accessToken?: string;
  shippingInfo?: ShippingInfo & { name?: string };
  isVip?: boolean;
  promocode?: string;
  language?: string;
  alwaysUseInclusiveTaxes?: boolean;
  productMetadata?: JsonObject;
};

type PurchaseRequest = {
  user_id: string;
  email: string;
  currencySymbol: string;
  productIds: string[];
  language: string;
  shouldValidateAccessToken: boolean;
  accessToken?: string;
  shippingInfo?: ShippingInfoWithName;
  isVip?: boolean;
  discountCode?: string;
  alwaysUseInclusiveTaxes: boolean;
  productMetadata?: JsonObject;
};

type ProductId = string;
type TransactionId = string;
type PurchaseResponse = Record<ProductId, TransactionId>;

type GetRecipeResponse = {
  recipe: Recipe;
};

type ZumbaSubscriptionResponse = {
  isSubscribed: boolean;
};

export type GetMealPlansParams = {
  dietaryRestriction: string;
  currentWeek?: string;
  weekCodename?: string;
  times?: string[];
};

type MealPlan = {
  lunch: Recipe[];
  dinner: Recipe[];
};

type GetMealPlansResponse = {
  mealPlans?: Record<string, MealPlan>;
  breakfast?: Recipe[];
  snack?: Recipe[];
};

type CancelAddonSubscriptionResponse = {
  status: "success";
};

export type AddOnSubscription = {
  accessCode: string;
  autoRenewalEnabled: boolean;
  expiryTime: string;
  foreignId: string;
  id: string;
  inTrialPeriod: boolean;
  nextChargeAmount: number;
  planId: string;
  product:
    | "NUTRITION_COACHING"
    | "MEAL_WORKOUT_PLANNING"
    | "TELEHEALTH_RECURRING"
    | "PREMIUM_WITH_TEXT_COACHING";
  purchaseFlow: string;
  source: string;
  status:
    | "UNVERIFIED"
    | "PENDING"
    | "FRAUDULENT"
    | "ACTIVE"
    | "PAST_DUE"
    | "INACTIVE";
  timeAutoRenewalDisabled: string;
  timeCanceled: string;
  timeEnded: string;
  timeStarted: string;
  trialPeriodInDays: number;
};

/**
 * Validates discount promocode using the Discount validation API
 * @param promocode - String to validate
 * @returns Discount as a nonzero percentage
 */
export async function validateDiscount(promocode: string): Promise<number> {
  const request = {
    code: promocode,
  };

  const response = await send<DiscountResponse>(
    "POST",
    "/upsell_discounts/validate/",
    new URLSearchParams(request)
  );

  const discount = response.amount;

  if (discount > 100 || discount <= 0) {
    throw new Error(
      `Received an invalid discount of '${discount}%' when using promocode '${promocode}'`
    );
  }

  return discount;
}

export function generatePurchaseRequest({
  userId,
  email,
  currencySymbol,
  productIds,
  shouldValidateAccessToken,
  accessToken,
  shippingInfo,
  isVip,
  promocode,
  language,
  alwaysUseInclusiveTaxes,
  productMetadata,
}: PurchaseParams) {
  const request: PurchaseRequest = {
    email,
    currencySymbol,
    language: getLanguage(),
    shouldValidateAccessToken,
    user_id: userId,
    productIds,
    alwaysUseInclusiveTaxes: alwaysUseInclusiveTaxes ?? false,
  };

  if (accessToken) request.accessToken = accessToken;
  if (shippingInfo) {
    request.shippingInfo = {
      // if `shippingInfo.name` exists already, overwrite this
      name: `${shippingInfo.firstName} ${shippingInfo.lastName}`,
      ...shippingInfo,
    };
  }
  if (isVip) request.isVip = isVip;
  if (promocode) request.discountCode = promocode;
  if (language) request.language = language;
  if (productMetadata) request.productMetadata = productMetadata;

  return request;
}

export const extractAddonsFromPlacements = (
  placements: Record<string, Record<string, Offer[]>>,
  channelIds: string[]
) => {
  return channelIds.reduce((extractedAddons: Offer[], channelId: string) => {
    const singlePlacement = placements[channelId] || {};
    return extractedAddons.concat(singlePlacement.payment || []);
  }, []);
};

export function loadFastForwardAddonOffers() {
  const state = getStore().getState();
  const { upid } = state;
  const path = `/api/addons/v1/get_ffwd_course_add_ons/?upid=${upid}`;
  return send("GET", path);
}

export function purchaseAddons(params: PurchaseParams) {
  const request = generatePurchaseRequest(params);
  return send<PurchaseResponse>(
    "POST",
    "/api/payment/v2/purchase_add_on/",
    request
  );
}

export function checkExistingZumbaSubscription(email: string) {
  const isApp = isInApp();
  const { accessToken = "" } = (
    isApp ? getAccessToken() : getWebAuthCookie()
  ) as Record<string, string>;

  return send<ZumbaSubscriptionResponse>(
    "POST",
    `${appConfig.COACH_SERVER_URL}/zumba/checkVirtualPlusSubscription`,
    { email },
    { headers: { Authorization: `Bearer ${accessToken}` } }
  );
}

export function getRecipe(id: string) {
  return send<GetRecipeResponse>("GET", `/api/recipes/v1/${id}/`);
}

export type ShopifyUrlOptions = {
  slug?: string;
  utmSource?: string;
  utmMedium?: string;
  utmCampaign?: string;
  utmContent?: string;
  utmTerm?: string;
};

export function getShopifyUrl({
  slug,
  utmSource,
  utmMedium,
  utmCampaign,
  utmContent,
  utmTerm,
}: ShopifyUrlOptions) {
  const params = new URLSearchParams();

  if (slug) {
    params.append("product_slug", slug);
  }

  if (utmSource) {
    params.append("utm_source", utmSource);
  }

  if (utmMedium) {
    params.append("utm_medium", utmMedium);
  }

  if (utmCampaign) {
    params.append("utm_campaign", utmCampaign);
  }

  if (utmContent) {
    params.append("utm_content", utmContent);
  }

  if (utmTerm) {
    params.append("utm_term", utmTerm);
  }

  return send<string>(
    "GET",
    "/api/addons/v1/get_authenticated_noom_shop_url",
    null,
    { params }
  );
}

export type ShopifyPurchaseEmailOptions = {
  slug?: string;
};

export const requestShopifyPurchaseEmail = ({
  slug,
  utmSource,
  utmMedium,
  utmCampaign,
  utmContent,
  utmTerm,
}: ShopifyUrlOptions) => {
  const body = new Map();

  if (slug) {
    body.set("product_slug", slug);
  }

  if (utmSource) {
    body.set("utm_source", utmSource);
  }

  if (utmMedium) {
    body.set("utm_medium", utmMedium);
  }

  if (utmCampaign) {
    body.set("utm_campaign", utmCampaign);
  }

  if (utmContent) {
    body.set("utm_content", utmContent);
  }

  if (utmTerm) {
    body.set("utm_term", utmTerm);
  }

  return send(
    "POST",
    "/api/addons/v1/request_email_shop_url/",
    Object.fromEntries(body)
  );
};

export const getMealPlansV2 = async ({
  dietaryRestriction,
  weeks,
  times = [],
}) => {
  let weeksString = "";
  for (const week of weeks) {
    weeksString = `${weeksString}&weeks=${week}`;
  }

  let timesString = "";
  for (const time of times) {
    timesString = `${timesString}&times=${time}`;
  }

  const path = `/api/meal_plans/v2/?dietaryRestriction=${dietaryRestriction}${weeksString}${timesString}`;
  return send<GetMealPlansResponse>("GET", path);
};

export function loadAddonOffers(currency: string, channelIds: string[]) {
  return send<Record<string, Record<string, Offer[]>>>(
    "POST",
    "/api/addons/v1/get_offers/",
    { currency, channelIds, language: getLanguage() }
  );
}

export const fetchAddonSubscription = (
  subscriptionId: string,
  params?: { upid: string }
) => {
  let path = `/api/addons/v1/subscriptions/${subscriptionId}`;
  if (params) path += `?${new URLSearchParams(params)}`;
  return send<AddOnSubscription>("GET", path);
};

export function cancelAddonSubscription(params: {
  subscriptionId: string;
  upid: string;
}) {
  return send<CancelAddonSubscriptionResponse>(
    "POST",
    "/api/payment/v2/cancel_add_on_subscription",
    params
  );
}

export const extendAddonSubscriptionTrial = ({
  subscriptionId,
}: {
  subscriptionId: string;
}) => {
  return send("POST", "/api/payment/v2/accept_trial_extension/", {
    subscriptionId,
    language: getLanguage(),
  });
};

export const discountAddonSubscription = ({
  subscriptionId,
  expiryTime,
  oldPlanDuration,
  userId,
}: {
  subscriptionId: string;
  expiryTime: string;
  oldPlanDuration: number;
  userId: string;
}) => {
  return send("POST", "/api/payment/v2/accept_counter_offer/", {
    subscriptionId,
    language: getLanguage(),
    newPlan: {
      braintreeId: "DUMMY", // This field is never used for addon counteroffers, but may be used for program ones
    },
    expiryTime,
    oldPlanDuration,
    userId,
  });
};

let loaderPromise: Promise<boolean>;

function loadExperimentConflictingWithSummitCoursePack() {
  if (!loaderPromise) {
    loaderPromise = send<boolean>(
      "GET",
      "/api/addons/v1/is_user_ineligible_for_noom_summit/"
    );
  }

  return loaderPromise;
}

type UserAddress = {
  addressUid: string;
  accessCode: string;
  name: string;
  address1: string;
  address2?: string;
  city: string;
  region: string;
  zipcode: string;
  country: string;
  recipient: string;
};

export function getBillingAddress() {
  return send<UserAddress>(
    "GET",
    "/api/billing/customer_info/get_billing_address"
  );
}

/**
 * API to determine whether user has been enrolled in an in-app experiment that
 * conflicts with Noom-Summit. This is a temporary API until experiment enrollment
 * has been moved to app first open rather than upon program purchase.
 */
export const isInExperimentConflictingWithSummitCoursePack: SegmentFunction =
  (() => {
    function ret() {
      const summit_ineligible = window.localStorage.getItem(
        SUMMIT_LOCALSTORAGE_KEY
      );

      if (summit_ineligible === null) {
        // Note: (Pratik) quicksilver reads this value upon opening it before purchase
        // however the only way to know if summit is eligble is only after experiment
        // enrollment. Returning false is to prevent quicksilver from breaking and to
        // prevent from directly navigating to summit without the .load() from firing.
        // This will be removed when this API is removed.
        if (isDevMode) {
          return true;
        }

        throw new Error(
          "You should call isInExperimentConflictingWithSummitCoursePack.load() before calling this function"
        );
      }

      return summit_ineligible === "true";
    }

    ret.load = async () => {
      return loadExperimentConflictingWithSummitCoursePack().then(
        (isIneligible) => {
          window.localStorage.setItem(
            SUMMIT_LOCALSTORAGE_KEY,
            `${isIneligible}`
          );

          if (isIneligible) {
            trackEvent("IneligibleForNoomSummit");
          }
        }
      );
    };
    return ret;
  })();
