import { batch } from "react-redux";
import i18n from "src/locales";
import {
  PromoCodeErrorType,
  PromoCodeState,
  getPromoCodeState,
  isPromoCodeFromPriorSession,
  resetPromoCodeAppliedFromPriorSession,
  setPromoCode,
} from "@utils/redux/slices/promoCode";
import saveToBraze from "../../brazeUploader";
import { shift_days, toISO } from "../../datetime";
import { queryClient, send } from "../../fetch";
import { getDefaultTrialFee } from "../../plans";
import { paymentTypes, updateCheckoutState } from "../../redux/slices/checkout";
import { Plan } from "../../redux/slices/plans";
import {
  RecommendedPlanState,
  setTrialFee,
  updateRecommendedPlan,
} from "../../redux/slices/recommendedPlan";
import getStore, {
  AppDispatch,
  CoreReduxState,
  GetAppState,
} from "../../redux/store";
import { captureException } from "../../error";
import { prepareGrowthAPIParameters } from "../../services/api-params";
import { trackPurchase } from "../../services/ConversionTracker";
import { errorConstants } from "../../services/PurchaseErrorMessages";
import {
  isCA,
  isEnLanguage,
  isIntl,
  isInApp,
  isIOS,
  isHM,
  isAppEmail,
} from "../../userSegment";
import {
  shouldEnrollUser,
  isInAppWebPurchaseEligible,
  acceptedAMCoursepackOffer,
  acceptedMWCoursepackOffer,
  isPlanSelectionEligible,
  isDevMode,
  isUserEnrolledInMABExperiment,
  BANDIT_LOCAL_STORAGE_KEY,
  acceptedSleepCoursepackOffer,
  acceptedMovementCoursepackOffer,
  willReceiveSomeWomensHealthModule,
  acceptedGlp1CompanionOffer,
  isEligibleForCoachReferralBuyflow,
  acceptedNoomClinicalOffer,
  hasSelectedCompoundedPlan,
  isMedAsATierEnabled,
} from "../../userSegment/features";
import {
  trackBanditEvent,
  trackBuyflowEvent,
  trackEmailChanged,
  trackEvent,
} from "../tracker";
import {
  getReferralCode,
  validateReferralCode,
  submitReferralEmails,
} from "@utils/api/referrals";
import { decoratePurchaseRequestWithProductCatalog } from "@utils/api/purchase/productPlan";
import { SurveyAnswersState } from "@utils/redux/slices/surveyAnswers";
import {
  applyFreeMonthsOffer,
  isPlanEligibleForFreeMonthsOffer,
} from "@utils/freeMonthsOffer";
import { getSurveyAnswers } from "src/hooks/survey/answers";
import { prepareDataForBackend } from "src/utils/pace";
import {
  getCountryCode,
  getLanguage,
  getLocale,
  getMeristemContext,
} from "../../meristemContext";
import { createAppAsyncThunk } from "../../redux/async-thunk";
import { getSalesTax, AddOnSalesTaxParam } from "src/utils/api/addons/salesTax";
import {
  PaymentAddon,
  selectAllPaymentAddons,
  selectAMCoursepack,
  selectGlp1Coursepack,
  selectMovementCoursepack,
  selectMWCoursepack,
  selectPaymentAddonById,
  selectSleepCoursepack,
  updatePaymentAddon,
} from "src/utils/redux/slices/paymentAddon";
import { loadWinbackInfo } from "src/utils/redux/slices/winbackInfo";
import { getECTExperiments } from "src/utils/experiment";
import { JsonObject } from "type-fest";
import { trackTask } from "src/utils/monitoring/events/tasks";
import { setOptOutEmail } from "src/utils/consent/opt-out";
import { getPaymentMethod } from "src/checkout/tracking";
import { UserDataState } from "@utils/redux/slices/userData";
import { submitSupporterEmail } from "@utils/api/supporters";
import { getFallbackPromotionalOfferConfiguration } from "src/utils/fallbackPromotionalOffer";
import { DUMMY_MULTI_USER_PLAN_SECONDARY_EMAIL } from "src/utils/constants";
import { isValidZipCode } from "src/utils/validate";
import { selectResolvedPhysicalAddressShortRegion } from "src/utils/redux/slices/checkoutPhysicalAddress/address-selectors";
import { requiresPhysicalAddress } from "src/hooks/checkout/physical-address";
import { isInsideAndroidWebView } from "src/pageDefinitions/goto/utils";
import { getAndroidMessageHandlers } from "src/utils/in-app/messageHandlers";
import { getReferralMethodFromShareSource } from "src/utils/referrals/shareSourceTracking";
import { getURLParams } from "@utils/urlParams";
import { NoomBuyflowEvents } from "src/utils/monitoring/events";
import { isShowablePaymentAddon } from "src/utils/paymentAddon";
import { isHolidayPromocode } from "src/utils/holidayPromo";
import { updateMultiUserPlanState } from "src/utils/redux/slices/multiUserPlan";
import { filterAndMapCourseEnhancements } from "src/utils/courseEnhancementData";
import { getSessionState } from "src/pageDefinitions/session";

const PROMO_CODE_EXPIRY_DURATION = 60 * 60 * 24 * 2; // 48 hours
const PROMO_CODE_EXPIRY_DURATION_DEV = 30; // 30 sec for dev mode
const PROMO_CODE_VALIDATION_URL = "/discounts/api/v1/validate/";
export const SALES_TAX_CALCULATION_URL = "/api/tax";

export type SalesTaxAPIResponse = {
  is_tax_inclusive: boolean;
  plan: Plan;
  sales_tax_amount: number;
  sales_tax_state: string;
  sales_tax_rate: number;
  trial_fee_sales_tax_amount?: number;
  trial_fee_sales_tax_rate?: number;
  trial_fee_total_amount?: number;
  trial_fee_is_tax_inclusive?: number;
};

/**
 * Returns the plan with the discounted price (if any) after trying to apply a promo code.
 * To be used as helper function.
 */
async function attemptToApplyPromoCode(
  plan: RecommendedPlanState,
  promoCode: string,
  state: CoreReduxState = getStore().getState()
) {
  const { serverContext, surveyAnswers, userData } = state;

  const params: JsonObject = {
    noom_plan_id: plan.noom_plan_id,
    code: promoCode,
    trialFee: plan.trial_fee || getDefaultTrialFee(plan),
    forced_country_code: getMeristemContext().is_forced_country_code
      ? getCountryCode()
      : undefined,
    // NOTE(guorui): for a normal user, surveyAnswers.email will contain the user's email
    // and serverContext.email will be null.
    // serverContext.email is populated when the user deeplinks to the payment page
    // with the email param set
    // userData.email is populated when the email param is not set on a deeplink, and the
    // user fills in an email on the Name and Email form preceding the payment page
    email: serverContext.email || surveyAnswers.email || userData.email,
  };
  if (isPlanSelectionEligible() && isEnLanguage()) {
    params.promoCodeExpiryDurationSec = isDevMode()
      ? PROMO_CODE_EXPIRY_DURATION_DEV
      : PROMO_CODE_EXPIRY_DURATION;
  }

  // NOTE(patrick): For legacy reasons this endpoint sends data as x-www-form-urlencoded
  // but receives responses from the django backend as JSON.
  // Convert our params object into a URLSearchParam format to send to the server.
  // In the future, this can be replaced with JSON.stringify.
  const paramsPayload = new URLSearchParams();
  Object.entries(params).forEach(([key, value]) => {
    if (value != null) {
      paramsPayload.append(key, `${value}`);
    }
  });

  const response: Plan = await send(
    "POST",
    PROMO_CODE_VALIDATION_URL,
    paramsPayload,
    {
      params: prepareGrowthAPIParameters(),
    }
  );

  return response;
}

/**
 * Makes a call to the backend to compute the discounted plan if the promo code is valid. Discounted plan will be returned and the promo code redux slice will be updated.
 * If promo code is not valid, promo code redux slice will be updated with an error state.
 * @param plan The type of `plan` needs to be `RecommendedPlanState` because in some places we pass the recommendedPlan.
 * @param promoCode During this call we also validate the promo code and save it into the redux store if it is valid.
 * @param freeMonths The number of free months, in case we redeem a free months offer
 * @returns The plan with the prices discounted based on the promo code.
 */
export function applyPromoCode(
  plan: RecommendedPlanState,
  promoCode: string,
  freeMonths?: number,
  shouldUpdateExpirationDate = true
) {
  return async (dispatch: AppDispatch, getState: GetAppState) => {
    const promoCodeFromPriorSession = isPromoCodeFromPriorSession(promoCode);
    if (promoCodeFromPriorSession) {
      resetPromoCodeAppliedFromPriorSession();
    }
    const task = trackTask("RedeemingPromoCode", {
      promoCode,
      promoCodeFromPriorSession,
      freeMonths,
    });
    try {
      // MP-Rename: Legacy events to remove
      trackEvent("RedeemingPromoCode", {
        promoCode,
        freeMonths,
      });
      task.start();

      const response = await attemptToApplyPromoCode(
        plan,
        promoCode,
        getState()
      );

      if (response.formatted_promo_discount_amount === "0") {
        const toThrow: any = new Error("checkout:errorPromo");
        toThrow.i18nMessage = "checkout:errorPromo";
        throw toThrow;
      }

      const updatedPlan: Partial<Plan> = {
        ...plan,
        price: response.price,
        monthly_price: response.monthly_price,
        weekly_price: response.weekly_price,
        discounted_plan_price: response.discounted_plan_price,
        discount_info: response.discount_info,
        // NOTE(patrick)[2020/04/14]: The batch symbol can be modified by the server.
        // It is most likely because the curriculum is changed from HW to VIP in the
        // case the promo code is for a VIP user.
        // NOTE(patrick)[2020/04/14]: We get the batch_symbol in recommendedPlan but save it
        // as `curriculum` and pass it back to the payment endpoint as `curriculum` in
        // the plan. `batch_symbol` will still be set in recommendedPlan but it's not
        // to be confused with `curriculum`.
        ...(response.batch_symbol && { curriculum: response.batch_symbol }),
        // Honor promo codes that provide free trials.
        ...(response?.trial_fee === 0 && {
          trial_fee: 0,
          userSelectedTrialFee: 0,
        }),
      };

      let promoCodeExpirationDate = null;
      if (response.promo_code_expiration_date) {
        promoCodeExpirationDate = new Date(
          `${response.promo_code_expiration_date}Z`
        ).toISOString();
      }

      const newPromoCode: Partial<PromoCodeState> = {
        promoCodeError: "",
        promoCodeErrorType: null,
        promoCodeApplied: promoCode,
        promoDiscountAmount: response.promo_discount_amount,
        // if response.discount_info.discountType === PERCENT then this will be populated, otherwise 0
        promoDiscountPercentage: response.discount_info?.discountPercent,
        promoCodeIsVip: response.is_vip,
        promoCodeFreeMonths: freeMonths,
      };
      if (shouldUpdateExpirationDate) {
        newPromoCode.promoCodeExpirationDate = promoCodeExpirationDate;
      }

      dispatch(setPromoCode(newPromoCode));

      task.success({
        promoCodeExpirationDate,
        promoCodeIsVip: response.is_vip,
      });

      const discountType =
        response.discount_info?.discountType === "PERCENT"
          ? "percent"
          : "fixed_amount";
      // MP-Rename: Legacy events to remove
      trackEvent("PromoCodeRedeemed", {
        promoCode,
        promoCodeExpirationDate,
        offerType: freeMonths ? "free_months" : discountType,
        discountPercentage: response.discount_info?.discountPercent,
        discountFixedAmount: response.discount_info?.discountAmount,
        freeMonths,
      });

      if (response.is_vip) {
        trackEvent("RedeemedPromoCodeVIP", {
          promoCode,
        });
      }

      return updatedPlan;
    } catch (err: any) {
      const { response, responseData } = err;
      let promoCodeError = responseData?.message || err;
      let promoCodeErrorType: PromoCodeErrorType = "UnknownError";
      if (response?.status === 406) {
        trackEvent("InvalidPromoCode", { promoCode });
        promoCodeError = i18n.t("checkout:invalidCode");
        promoCodeErrorType = "IncorrectPromoCode";
      } else if (response?.status === 410) {
        promoCodeError = i18n.t("checkout:expiredCode");
        promoCodeErrorType = "PromoCodeRedemptionBlocked";
      } else if (err.i18nMessage) {
        promoCodeError = i18n.t(err.i18nMessage);
      } else {
        promoCodeError = i18n.t("checkout:genericErrorMessage");
      }
      dispatch(
        setPromoCode({
          promoCodeApplied: "",
          promoDiscountAmount: 0,
          promoDiscountPercentage: 0,
          promoCodeIsVip: false,
          promoCodeFreeMonths: null,
          promoCodeInitialCode: null,
          promoCodeError,
          promoCodeErrorType,
        })
      );
      if (
        getState().fallbackPromotionalOffer.hasUserAcceptedOffer &&
        promoCode ===
          getFallbackPromotionalOfferConfiguration().previouslySentPromo
      ) {
        trackEvent("InvalidPrefilledPromoCode");
      }
      trackEvent("PromoCodeRedemptionFailed", { promoCode });
      task.failed(err);
      return null;
    }
  };
}

/**
 * Applies the promo code to the recommended plan.
 * Side effects include: updating promo code state, recommended plan state and recomputing sales tax.
 */
export function applyPromoCodeToRecommendedPlan(
  promoCode: string,
  freeMonths?: number
) {
  return async (dispatch: AppDispatch, getState: GetAppState) => {
    const { recommendedPlan, promoCode: promoCodeSlice } = getState();

    // if persisted
    let promoCodeToApply = promoCodeSlice.promoCodeInitialCode || promoCode;
    if (isHolidayPromocode(promoCodeSlice.promoCodeInitialCode)) {
      promoCodeToApply = promoCode;
    }

    let plan: Partial<Plan> = await dispatch(
      applyPromoCode(recommendedPlan, promoCodeToApply, freeMonths)
    );

    if (isPlanEligibleForFreeMonthsOffer(plan)) {
      plan = await dispatch(applyFreeMonthsOffer(plan, promoCodeToApply));
    }

    if (!plan) {
      // if plan is null an exception occurred inside applyPromoCode or when trying to redeem the free months offer
      return null;
    }

    const shouldDiscountSelectedPlan =
      !(isPlanSelectionEligible() && plan?.billing_cycle_in_months === 1) ||
      plan.is_vip;
    if (shouldDiscountSelectedPlan) {
      dispatch(updateRecommendedPlan(plan));

      // Honor promo codes that provide free trials.
      // Reason behind this is that we need to explicitly update the trial fee, otherwise
      // it would default to the previous value
      if (plan.trial_fee === 0) {
        dispatch(setTrialFee(0));
      }
    }

    // NOTE(Rose): In the case that promocode is entered after zipcode, the disclaimer and order breakdown don't update
    // with the right totals. Forcing rerender by updating state or using this.forceUpdate() don't seem to work, as the
    // rendering seems tied to calculateRecommendedPlanSalesTax().
    return dispatch(calculateRecommendedPlanSalesTax());
  };
}

function hasValidBillingAddress(state = getStore().getState()) {
  const { checkout } = state;
  const { billingAddress, billingAddressErrors } = checkout;
  return (
    !billingAddressErrors?.ZIPCODE &&
    billingAddress?.zipcode &&
    // Both region and zipcode are required to calculate sales tax for Canadian users
    (!isCA() || !!billingAddress.region)
  );
}

function hasValidPhysicalAddress(state = getStore().getState()) {
  const resolvedAddress = selectResolvedPhysicalAddressShortRegion(state);
  // Both region and zipcode are required to calculate sales tax for Canadian users
  return (
    isValidZipCode(resolvedAddress?.zipcode) &&
    (!isCA() || !!resolvedAddress.region)
  );
}

function hasValidAddressForTaxCalculation(state = getStore().getState()) {
  return (
    hasValidBillingAddress(state) ||
    (requiresPhysicalAddress(state) && hasValidPhysicalAddress(state))
  );
}

export async function isPromoCodeValid(
  plan: RecommendedPlanState,
  promoCode: string
) {
  const task = trackTask("ValidatingPromoCode", {
    promoCode,
  });
  try {
    task.start();

    const planWithPromoCodeApplicationAttempt = await attemptToApplyPromoCode(
      plan,
      promoCode
    );

    task.success();
    return planWithPromoCodeApplicationAttempt.promo_discount_amount !== 0;
  } catch (err: any) {
    task.failed(err);
    return false;
  }
}

export function calculateAddonSalesTax() {
  return async (dispatch: AppDispatch, getState: GetAppState) => {
    const state = getState();
    const {
      checkout,
      userData,
      recommendedPlan,
      serverContext,
      surveyAnswers,
      paymentAddon,
    } = state;

    if (!hasValidAddressForTaxCalculation()) {
      return Promise.resolve();
    }

    const paymentAddons = selectAllPaymentAddons(paymentAddon).filter((addon) =>
      isShowablePaymentAddon(addon)
    );

    const { billingAddress } = checkout;
    const physicalAddress = selectResolvedPhysicalAddressShortRegion(state);

    const salesTaxParams: AddOnSalesTaxParam = {
      email: userData.email || serverContext.email || surveyAnswers.email,
      currency: recommendedPlan.currency,
      user_id: userData.user_id,
      productIds: paymentAddons.map((addon) => addon.addon_product_id),
    };

    if (hasValidBillingAddress(state)) {
      salesTaxParams.billing_address = {
        ...billingAddress,
        zipcode: billingAddress.zipcode.split("-")[0],
      };
    }
    if (requiresPhysicalAddress(state) && hasValidPhysicalAddress(state)) {
      salesTaxParams.home_address = {
        ...physicalAddress,
        zipcode: physicalAddress.zipcode.split("-")[0],
      };
    }

    return getSalesTax(salesTaxParams)
      .then((response) => {
        const taxUpdates = response.line_items.map((item) => {
          const addOn = selectPaymentAddonById(paymentAddon, item.product_id);
          const update: PaymentAddon = {
            addon_product_id: item.product_id,
            sales_tax_rate: item.sales_tax_rate,
            is_tax_inclusive: response.is_tax_inclusive,
            sales_tax_amount: addOn.price !== 0 ? item.sales_tax_amount : 0,
          };
          return update;
        });
        batch(() => {
          taxUpdates.forEach((update) => {
            dispatch(updatePaymentAddon(update));
          });
        });
      })
      .catch((err: any) => {
        captureException(err);
      });
  };
}

/**
 * Given a US zipcode or CA postal code and region, fetch calculated sales tax and then
 * update the plan stored in the state and salesTaxAmount.
 */
export function calculateRecommendedPlanSalesTax() {
  return async (dispatch: AppDispatch, getState: GetAppState) => {
    const state = getState();
    const { recommendedPlan, multiUserPlan } = state;
    const { originalRecommendedPlan } = multiUserPlan;

    if (!hasValidAddressForTaxCalculation(state)) {
      return Promise.resolve();
    }

    // Update tax information for original recommended plan as well
    if (originalRecommendedPlan) {
      const originalPlanTaxCalculationPayload = getTaxCalculationPayload(
        originalRecommendedPlan,
        state
      );

      send(
        "POST",
        SALES_TAX_CALCULATION_URL,
        originalPlanTaxCalculationPayload,
        {
          params: prepareGrowthAPIParameters(),
        }
      )
        .then((data: SalesTaxAPIResponse) => {
          const { plan } = data;
          dispatch(
            updateMultiUserPlanState({
              originalRecommendedPlan: {
                ...originalRecommendedPlan,
                ...plan,
              },
            })
          );
        })
        .catch((err: any) => {
          captureException(err);
        });
    }

    const recommendedPlanTaxCalculationPayload = getTaxCalculationPayload(
      recommendedPlan,
      state
    );

    return send(
      "POST",
      SALES_TAX_CALCULATION_URL,
      recommendedPlanTaxCalculationPayload,
      {
        params: prepareGrowthAPIParameters(),
      }
    )
      .then((data: SalesTaxAPIResponse) => {
        const { plan } = data;
        // hacky temp fix: tax endpoint returns different payment source
        // clearing up the payment form
        delete plan.payment_source;
        dispatch(
          updateRecommendedPlan({
            ...plan,
            trial_fee_total_amount: data.trial_fee_total_amount,
          })
        );
        dispatch(
          updateCheckoutState({
            salesTaxAmount: data.sales_tax_amount,
            salesTaxRate: data.sales_tax_rate || 0,
            salesTaxState: data.sales_tax_state,
            isTaxInclusive: data.is_tax_inclusive,
            trialFeeSalesTaxAmount: data.trial_fee_sales_tax_amount,
            trialFeeSalesTaxRate: data.trial_fee_sales_tax_rate,
            isTrialFeeTaxInclusive: data.trial_fee_is_tax_inclusive,
          })
        );
        return data;
      })
      .catch((err: any) => {
        captureException(err);
      });
  };
}

function getTaxCalculationPayload(
  plan: RecommendedPlanState,
  state: CoreReduxState
) {
  const { checkout, serverContext, surveyAnswers } = state;
  const promoCode = getPromoCodeState();
  const { billingAddress } = checkout;
  const physicalAddress = selectResolvedPhysicalAddressShortRegion(state);

  const payload = {
    country: getCountryCode(),
    continentCode: getMeristemContext().continent_code,
    noomPlanId: plan.noom_plan_id,
    promoCode: promoCode.promoCodeApplied,
    trialFee: plan.trial_fee,
    // NOTE(guorui): for a normal user, surveyAnswers.email will contain the user's email
    // and serverContext.email will be null.
    // serverContext.email is populated when the user deeplinks to the payment page
    // with the email param set
    email: serverContext.email || surveyAnswers.email,
    zipcode: undefined,
    billingAddress: undefined,
    homeAddress: undefined,
    returnTaxForTaxInclusivePlans: undefined,
  };

  if (hasValidBillingAddress(state)) {
    payload.zipcode = billingAddress.zipcode;
    payload.billingAddress = {
      ...billingAddress,
      zipcode: billingAddress.zipcode.split("-")[0],
    };
  }
  if (requiresPhysicalAddress(state) && hasValidPhysicalAddress(state)) {
    payload.zipcode = physicalAddress.zipcode;
    payload.homeAddress = {
      ...physicalAddress,
      zipcode: physicalAddress.zipcode.split("-")[0],
    };
  }

  // EN-INTL surface includes information about tax-inclusive pricing
  if (isIntl()) {
    payload.returnTaxForTaxInclusivePlans = true;
  }

  return payload;
}

const PURCHASE_URL = "/api/payment/v2/purchase_program/";

export interface PurchaseProgramResponse {
  eltv_13_months: number;
  email: string;
  name: string;
  payment_cc_last_4: string;
  payment_cc_type: string;
  subscriptionId: string;
  user_id: string;
  upid: string;
}

export const requestPurchase = createAppAsyncThunk(
  "checkoutPurchase/request",
  async (
    params: {
      curriculum?: string;
      vipCurriculum?: string;
      paymentNonce: string;
      recaptchaToken: string;
    },
    thunkApi
  ) => {
    const coreState = thunkApi.getState();
    const { recommendedPlan } = coreState;

    const purchaseRequest = await preparePurchaseRequest({
      coreState,
      curriculum: params.curriculum,
      vipCurriculum: params.vipCurriculum,
      paymentMethodNonce: params.paymentNonce,
      recaptchaToken: params.recaptchaToken || null,
    });

    const task = trackTask("StartSubscription", {
      paymentMethod: getPaymentMethod(coreState.checkout, coreState.promoCode),
      planId: recommendedPlan.braintree_id,
    });
    let response: PurchaseProgramResponse;
    try {
      task.start();
      response = await send("POST", PURCHASE_URL, purchaseRequest, {
        params: prepareGrowthAPIParameters(),
      });
      task.success();
    } catch (e) {
      const errorData = e.responseData;
      let errorType = errorData?.errorType || errorConstants.errorUnknown;
      // NOTE(sumin): Quick hack to be backwards compatible but show sales tax location error.
      if (errorData?.errorCode === errorConstants.errorSalesTaxLocation) {
        errorType = errorConstants.errorSalesTaxLocation;
      }

      task.failed(e, {
        error: errorType,
        result: errorType,
        request: purchaseRequest,
        errorData,
      });
      throw e;
    }
    // After successful signup, track events
    return {
      response,
      request: purchaseRequest,
    };
  }
);

export type PaymentMethodType = "PayPal" | "SEPA" | "CreditCard";

export function getPaymentMethodType(
  paymentType: paymentTypes
): PaymentMethodType {
  switch (paymentType) {
    case paymentTypes.PAYPAL:
      return "PayPal";
    case paymentTypes.SEPA:
      return "SEPA";
    case paymentTypes.CREDIT_CARD:
      return "CreditCard";
    default:
      throw new Error(`Unhandled payment type: ${paymentType}`);
  }
}

async function preparePurchaseRequest({
  coreState,
  curriculum,
  vipCurriculum,
  paymentMethodNonce,
  recaptchaToken,
}: {
  coreState: CoreReduxState;
  curriculum: string;
  vipCurriculum: string;
  paymentMethodNonce: string;
  recaptchaToken: string;
}) {
  // Extract necessary state from Redux
  const { checkout, paymentEnrollmentForm, userData, recommendedPlan } =
    coreState;
  const promoCode = getPromoCodeState(coreState);

  const experiments = getECTExperiments();

  const name = paymentEnrollmentForm.enrollmentInfo.name ?? userData.name;
  const email =
    paymentEnrollmentForm.enrollmentInfo.email.toLowerCase() ??
    userData.email.toLowerCase();
  const paymentType = promoCode.promoCodeIsVip
    ? paymentTypes.CREDIT_CARD
    : checkout.paymentType;
  const paymentMethod = getPaymentMethodType(paymentType);
  // NOTE(patrick): growthProgramStartDateISO is used by braze and is required to be ISO8601.
  const growthProgramStartDateISO = toISO();
  const expirationDate = shift_days(recommendedPlan.trial_duration, new Date());
  const growthTrialEndDate = expirationDate.toLocaleDateString(getLocale());
  const growthTrialEndDateISO = toISO(expirationDate);

  let paymentProcessor;
  if (checkout.paymentType === "PAYPAL") {
    paymentProcessor = "BRAINTREE";
  } else if (checkout.walletPay || checkout.paymentType === paymentTypes.SEPA) {
    paymentProcessor = "STRIPE";
  } else {
    paymentProcessor = recommendedPlan.payment_source;
  }

  // Assemble the purchase request.
  let purchaseRequest = {
    name,
    email,
    countryCode: getCountryCode(),
    continentCode: getMeristemContext().continent_code,
    noomPlanId: recommendedPlan.noom_plan_id,
    braintreeId: recommendedPlan.braintree_id,
    merchantAccount: recommendedPlan.merchant_account,
    language: getLanguage(),

    curriculum:
      recommendedPlan.curriculum ||
      (promoCode.promoCodeIsVip && vipCurriculum) ||
      curriculum,
    inAppPurchase: isInAppWebPurchaseEligible(coreState),
    courseAddOns: await getCourseAddOnsToPurchase(),

    paymentMethodType: paymentMethod,
    plan: recommendedPlan,
    paymentProcessor,
    zipcode: checkout.billingAddress?.zipcode,
    growthProgramStartDateISO,
    growthTrialEndDate,
    growthTrialEndDateISO,

    billingAddress: {
      ...(checkout.billingAddress || {
        country: getCountryCode(),
      }),
      name: checkout.billingName,
    },
    experiments,
    trialFee: undefined,
    paymentMethodNonce,
    recaptchaToken,

    subscriptionSaleItemId: undefined,
    oneSourceTaxCode: recommendedPlan?.one_source_tax_code,
    trialSaleItemId: undefined,

    ...(promoCode.promoCodeApplied && {
      promoCodeApplied: promoCode.promoCodeApplied,
      promoDiscountAmount: promoCode.promoDiscountAmount,
      promoCodeIsVip: promoCode.promoCodeIsVip,
    }),

    ...(promoCode.promoCodeApplied &&
      promoCode.promoCodeFreeMonths && {
        initialPromoCode: promoCode.promoCodeInitialCode,
        swappedPlanDuration:
          recommendedPlan.billing_cycle_in_months -
          promoCode.promoCodeFreeMonths,
      }),

    ...(isEligibleForCoachReferralBuyflow() && {
      referralCode: getSessionState("browser").referralInfo.referralCode,
    }),

    shouldEnroll: undefined,
    growthPaymentPayPal: undefined,
    secondaryUserEmail: undefined,
    homeAddress: undefined,
    setSubscriptionShippingAddressFromBilling:
      acceptedNoomClinicalOffer() && hasSelectedCompoundedPlan()
        ? true
        : undefined,
  };

  if (!isHM()) {
    const surveyAnswers = getSurveyAnswers(coreState);
    const weightLossData = prepareDataForBackend(surveyAnswers);

    if (weightLossData) {
      // Need to rename weightInKg field to startWeightInKg for setActiveGoalUsingPOST endpoint
      const { weightInKg, ...otherProps } = weightLossData;
      const newWeightLossData = { startWeightInKg: weightInKg, ...otherProps };
      (purchaseRequest as any).weightLossData = newWeightLossData;
    }
  }

  purchaseRequest = decoratePurchaseRequestWithProductCatalog(
    purchaseRequest,
    recommendedPlan
  );

  purchaseRequest = decoratePurchaseRequestWithUrlParams(purchaseRequest);

  // Pass in shouldEnroll only if eligible for collapsed payment enrollment UI
  // NOTE: app-email route has a bug where users are not enrolled into the program, but they have an account created
  if (shouldEnrollUser(coreState) || isAppEmail()) {
    purchaseRequest.shouldEnroll = true;
  }

  if (checkout.paymentType === paymentTypes.PAYPAL) {
    purchaseRequest.growthPaymentPayPal = checkout.paypalAccount;
  }

  if (checkout.multiUserPlan.toggled) {
    purchaseRequest.secondaryUserEmail =
      userData.noomTogetherState?.familyMemberEmail ||
      DUMMY_MULTI_USER_PLAN_SECONDARY_EMAIL;
  }

  return purchaseRequest;
}

async function getCourseAddOnsToPurchase() {
  const store = getStore();
  const { userData, paymentEnrollmentForm } = store.getState();
  if (userData.email !== paymentEnrollmentForm.enrollmentInfo.email) {
    await store.dispatch(loadWinbackInfo(true));
  }
  return filterAndMapCourseEnhancements(
    { filterFunctionKey: "isAccepted" },
    {
      mapFunctionKey: "getProductId",
    }
  );
}

function decoratePurchaseRequestWithUrlParams(
  purchaseRequest: any
): typeof purchaseRequest {
  const urlParams = new URLSearchParams(window.location.search);

  const newPurchaseRequest = { ...purchaseRequest };
  if (urlParams.has("tkbl_cvuuid")) {
    newPurchaseRequest.talkableCvuuid = urlParams.get("tkbl_cvuuid");
  }

  return newPurchaseRequest;
}

function trackAndroidPurchaseCompleted(foreignPlanId: string) {
  const messageHandlers = getAndroidMessageHandlers();

  if (!messageHandlers.trackPurchaseCompleted) {
    trackEvent("AndroidTrackPurchaseCompletedCallbackNotFound");
  } else {
    trackEvent("AndroidTrackPurchaseCompletedCallbackFound");
    messageHandlers.trackPurchaseCompleted(
      JSON.stringify({
        foreignPlanId,
      })
    );
  }
}

async function maybeTrackRefereeSignup(
  userData: UserDataState,
  promoCode: PromoCodeState
) {
  const { referral_code: referralCode, share_source: shareSource } =
    getURLParams();

  if (!referralCode) {
    return;
  }

  const isValidReferralCode = await validateReferralCode(referralCode);
  if (!isValidReferralCode) {
    return;
  }

  trackBuyflowEvent("RefereeSignup", {
    refereeGrowthUserId: userData.user_id,
    refereeAccessCode: userData.access_code,
    promoCode: promoCode.promoCodeApplied,
    referralMethod: getReferralMethodFromShareSource(shareSource),
  });
}

export async function trackPurchaseSuccess(
  response: PurchaseProgramResponse,
  email: string,
  name: string
) {
  const coreState = getStore().getState();
  const { recommendedPlan, checkout, userData, paymentAddon, promoCode } =
    coreState;
  // Track the signup.
  const trackerPayloadMixpanel = {
    planId: recommendedPlan.braintree_id,
    planDuration: recommendedPlan.billing_cycle_in_months,
    planCurrency: recommendedPlan.currency,
    planOriginalPrice: recommendedPlan.regular_price,
    planPrice: recommendedPlan.price,
    hasTrial: recommendedPlan.has_trial,
    paymentMethod: getPaymentMethod(coreState.checkout, promoCode),
    paymentCCLast4: response.payment_cc_last_4,
    paymentCCType: response.payment_cc_type,
    trialFeeAmount: recommendedPlan.trial_fee || 0,
    ...response,
  };
  trackEvent("OnSignedUp", trackerPayloadMixpanel);

  trackEvent("EnhancementsPurchased", {
    enhancements: checkout.paymentAddonsInCart,
  });

  if (isUserEnrolledInMABExperiment()) {
    trackBanditEvent("BanditFeedbackProvided", {
      banditKey: window.localStorage.getItem(BANDIT_LOCAL_STORAGE_KEY),
      banditFeedbackValue: 1,
      banditRecommendationUniqueId: userData.user_id,
    });
  }

  const trackerPayload: NoomBuyflowEvents["signupCompleted"] = {
    planId: recommendedPlan.braintree_id,
    planDurationMonth: recommendedPlan.billing_cycle_in_months,
    planCurrency: recommendedPlan.currency,
    planOriginalPriceUsd: recommendedPlan.regular_price,
    planPriceUsd: recommendedPlan.price,
    hasTrial: recommendedPlan.has_trial,
    paymentMethod: getPaymentMethod(coreState.checkout, promoCode),
    subscriptionId: response.subscriptionId,
    trialFeeAmount: recommendedPlan.trial_fee || 0,
    program: isHM(coreState) ? "hm" : "hw",
    promoCodeApplied: promoCode.promoCodeApplied,
  };
  trackBuyflowEvent("SignupCompleted", trackerPayload, {
    blockRoutingToMixpanel: true,
  });
  if (willReceiveSomeWomensHealthModule()) {
    trackEvent("WomensHealthPurchased");
  }
  trackEvent("CoursePackOfferPurchased", {
    answer: acceptedMWCoursepackOffer() ? "yes" : "no",
    price: selectMWCoursepack(paymentAddon)?.price,
  });
  trackEvent("CoursePackAMOfferPurchased", {
    answer: acceptedAMCoursepackOffer() ? "yes" : "no",
    price: selectAMCoursepack(paymentAddon)?.price,
  });
  trackEvent("CoursePackSleepOfferPurchased", {
    answer: acceptedSleepCoursepackOffer() ? "yes" : "no",
    price: selectSleepCoursepack(paymentAddon)?.price,
  });
  trackEvent("CoursePackMovementOfferPurchased", {
    answer: acceptedMovementCoursepackOffer() ? "yes" : "no",
    price: selectMovementCoursepack(paymentAddon)?.price,
  });
  trackEvent("CoursePackMedCompanionOfferPurchased", {
    answer: acceptedGlp1CompanionOffer() ? "yes" : "no",
    price: selectGlp1Coursepack(paymentAddon)?.price,
  });

  trackEmailChanged("purchase", email, coreState);
  setOptOutEmail(email);

  if (isInApp(coreState) && isIOS()) {
    await saveToBraze({ event: { event_name: "ios_app_signup" } });
  }

  if (checkout?.multiUserPlan?.toggled) {
    await trackMultiUserPlanPurchase(coreState);
  }

  // Fire tracking pixels
  const surveyAnswers = getSurveyAnswers(coreState);

  await Promise.all([
    trackReferralEmails(surveyAnswers, email, name, userData),
    trackSubmittedSupporter(surveyAnswers, email),
    maybeTrackRefereeSignup(userData, promoCode),
  ]);
  // if the user is buying med we will not signal back the purchase to 3rd parties
  if (!acceptedNoomClinicalOffer()) {
    trackPurchase(response.email, recommendedPlan, surveyAnswers, response);
  }

  if (acceptedNoomClinicalOffer() && isMedAsATierEnabled()) {
    const isCompounded = hasSelectedCompoundedPlan();
    trackBuyflowEvent("NoomMedTierPurchased", {
      growthProductId: recommendedPlan.braintree_id,
      noomPlanDuration: recommendedPlan.billing_cycle_in_months,
      pageId: "noom_med_buyflow_checkout",
      saleItemId: recommendedPlan.sale_item_id,
      sku: isCompounded ? "noom_med_compounded" : "noom_med_branded",
      tier: "noom_med",
    });
  }

  // NOTE(george): We are using this noom event to log the eltv values generated by the current model
  //               in the data lake. To be deleted after we migrate to v2 in Q3.
  trackBuyflowEvent("BuyflowPaymentEltvComputed", {
    eltvValue: response.eltv_13_months,
  });

  if (isInsideAndroidWebView()) {
    trackAndroidPurchaseCompleted(recommendedPlan.braintree_id);
  }
}

async function trackMultiUserPlanPurchase(coreState: CoreReduxState) {
  const { recommendedPlan, multiUserPlan } = coreState;
  // Track both event until peak is over, just in case
  trackEvent("PurchasedMultiUserPlan", {
    noomPlanId: recommendedPlan?.noom_plan_id,
    planId: recommendedPlan?.braintree_id,
    originalNoomPlanId: multiUserPlan?.originalRecommendedPlan?.noom_plan_id,
    originalPlanId: multiUserPlan?.originalRecommendedPlan?.braintree_id,
  });
  trackBuyflowEvent("MultiUserPlanPurchased", {
    newPlanId: recommendedPlan?.braintree_id,
    originalPlanId: multiUserPlan?.originalRecommendedPlan?.braintree_id,
    noomPlanDuration: recommendedPlan.billing_cycle_in_months,
  });
}

async function trackReferralEmails(
  surveyAnswers: SurveyAnswersState,
  email: string,
  name: string,
  userData: UserDataState
) {
  if (surveyAnswers.inviteFriendsFamily?.length > 0) {
    // const referralCode = await getReferralCode();
    const referralCode = await queryClient.fetchQuery(
      ["referralCode"],
      getReferralCode
    );
    surveyAnswers.inviteFriendsFamily.forEach((referreeEmail) =>
      trackBuyflowEvent("ReferralSubmitted", {
        referrerGrowthUserId: userData.user_id,
        referrerAccessCode: userData.access_code,
        referralMethod: "EMAIL",
        referralEntryPoint: "BUYFLOW",
        refereeEmailAddress: referreeEmail,
      })
    );
    await submitReferralEmails(
      email,
      surveyAnswers.inviteFriendsFamily,
      "buyflow",
      referralCode,
      { signedUp: true, name }
    );
  }
}

async function trackSubmittedSupporter(
  { firstName, supporterEmail, supporterName }: SurveyAnswersState,
  email: string
) {
  if (supporterEmail) {
    await submitSupporterEmail({
      userEmail: email,
      userName: firstName,
      supporterEmail,
      supporterName,
      addSupporterAsMember: true,
      hasSignedUp: true,
    });
  }
}
