import { send } from "@utils/fetch";

import getStore from "src/utils/redux/store";
import { getSearchQuery, getURLParams } from "../urlParams";
import { isEU, isMobile } from "../userSegment";
import { prepareGrowthAPIParameters } from "../services/api-params";
import { captureException } from "src/utils/error";
import { trackEvent } from "./tracker";
import { errorConstants } from "../services/PurchaseErrorMessages";
import { getCountryCode, getLanguage } from "../meristemContext";
import { getECTExperiments } from "../experiment";
import { NoomError } from "../error/NoomError";
import { Opaque } from "type-fest";
import { updateSessionState } from "src/pageDefinitions/session";

const API_ENDPOINT_V1 = "/api/enrollment/v1/enroll/";

type DeepLinkString = Opaque<string>;

declare module "src/pageDefinitions/session" {
  interface BrowserSessionState {
    claimedByEmail?: string;
    enrollmentResult?: string;
    appDeepLink?: DeepLinkString;
  }
}

interface BaseEnrollmentData {
  name: string;
  email: string;
  password: string;
  gender: string;
  age: number;
  heightInCm: number;
  weightInKg: number;
  language: string;
  isNursing?: boolean;
}

export interface EnrollmentData extends BaseEnrollmentData {
  phone_number: string;
  upid: string;
  activityLevel: string;
  piv?: boolean;
}

export interface CollapsedEnrollmentData extends BaseEnrollmentData {
  phoneNumber: string;
}

export interface EnrollmentParams {
  action: string;
  enrollmentData?: EnrollmentData;
  piv?: boolean;
  phone_number: string;
  email: string;

  curriculum?: string;
}

export interface CollapsedEnrollmentParams {
  userProfileData: CollapsedEnrollmentData;
  curriculum?: string;
}

export interface EnrollmentResult {
  result: string;
  claimed_by_email?: string;
}

export class EnrollmentError extends NoomError {
  constructor(public code: string = "Unknown") {
    super(`Enrollment Error: ${code}`);
  }
}

function getReduxProperties() {
  const state = getStore().getState();
  const { userData, serverContext } = state;
  return {
    is_eu_citizen: isEU(),
    gdpr_consent: serverContext.gdpr_consent,
    country_code: getCountryCode(),
    language: getLanguage(),
    user_id: userData.user_id,
    experiments: getECTExperiments(),
    device_is_mobile: isMobile(),
  };
}

function prepareEnrollmentRequest(params) {
  const payload = {
    ...params,
    ...getReduxProperties(),
  };
  return payload;
}

export function enrollUser(
  params: EnrollmentParams
): Promise<EnrollmentResult> {
  const payload = prepareEnrollmentRequest(params);
  const urlParams = new URLSearchParams(getURLParams());
  prepareGrowthAPIParameters(urlParams);

  return send(
    "POST",
    `${API_ENDPOINT_V1}${getSearchQuery(urlParams)}`,
    payload
  );
}

export async function requestEnrollment(params: CollapsedEnrollmentParams) {
  // Attempt to enroll the user.
  let enrollmentResult: string;
  let claimedByEmail: string;
  let deeplinkUrl: string;
  let success = false;

  try {
    const payload = prepareEnrollmentRequest({
      action: "create_account",

      email: params.userProfileData.email,
      name: params.userProfileData.name,
      password: params.userProfileData.password,
      phone_number: params.userProfileData.phoneNumber,

      ...params,
    });
    const urlParams = new URLSearchParams(getURLParams());
    prepareGrowthAPIParameters(urlParams);

    const json = await send(
      "POST",
      `/api/enrollment/v2/create_account/${getSearchQuery(urlParams)}`,
      payload
    );
    ({
      result: enrollmentResult,
      claimed_by_email: claimedByEmail,
      deeplink_url: deeplinkUrl,
    } = json);
    const successResults = [
      "account_created",
      "free_account_upgraded_facebook",
      "free_account_upgraded_google",
      "free_account_upgraded",
      "account_exists",
    ];
    if (successResults.includes(enrollmentResult)) {
      success = true;
    }
  } catch (err) {
    captureException(err);
  }

  trackEvent("OnCheckoutEnrollment", {
    result: enrollmentResult,
    success,
  });

  if (!success) {
    if (enrollmentResult === "account_already_has_program") {
      throw new EnrollmentError(errorConstants.errorActiveSubscription);
    }
    if (enrollmentResult === "email_address_is_invalid") {
      throw new EnrollmentError(errorConstants.errorInvalidEmail);
    }
    if (enrollmentResult === "pending_deletion_request") {
      throw new EnrollmentError(errorConstants.errorPendingDeletionRequest);
    }
    throw new EnrollmentError();
  }

  updateSessionState("browser", {
    enrollmentResult,
    claimedByEmail,
    appDeepLink: deeplinkUrl as DeepLinkString,
  });

  return { enrollmentResult, claimedByEmail };
}
