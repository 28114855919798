/* eslint-disable */
import { LibCategories } from "src/utils/consent";
import { loadScript } from "src/utils/scriptLoader";

declare const window: any;
window._linkedin_partner_id = "35076";
window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
window._linkedin_data_partner_ids.push(window._linkedin_partner_id);

export function loadLinkedIn() {
  return loadScript(
    "https://snap.licdn.com/li.lms-analytics/insight.min.js",
    [LibCategories.targetingCookies],
    { enableCORS: false }
  );
}
