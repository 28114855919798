import { trackBuyflowEvent, trackEvent } from "src/utils/api/tracker";
import { isReferrerFromNoom } from "src/utils/services/VisitTracker";
import { getNavType } from "src/utils/timing";
import { isInApp } from "src/utils/userSegment";
import { useOnce } from "./lifecycle";
import {
  getSessionState,
  updateSessionState,
} from "src/pageDefinitions/session";
import { Opaque } from "type-fest";
import { getLeadTrackingProperties } from "@utils/tracking/leadTrackingProperties";
import {
  isEligibleForCompoundedMed,
  isPaidSocialTraffic,
  isPaidTraffic,
} from "src/utils/userSegment/features";
import { getURLParams } from "src/utils/urlParams";
import { getConsent } from "src/utils/consent";
import { NoomBuyflowEvents } from "src/utils/monitoring/events";
import { getRouteId, getSubdivision } from "src/utils/meristemContext";
import { routeConstants } from "src/utils/constants";
import { MedRebootRolloutEligibleStates } from "src/components/refactored-survey/question-sets/insurance-survey-questions/utils/insuranceConstants";

type SessionTrackers = Opaque<{ eventName: string; expiryTime: number }[]>;

declare module "src/pageDefinitions/session" {
  interface BrowserSessionState {
    /** Client local time when session was initiated. */
    sessionInitiated?: string;
    activeSessionTrackers?: SessionTrackers;
  }
}

export function trackAppInit() {
  if (!getSessionState("browser").sessionInitiated) {
    // Consumed upstream in datadog adapter
    trackEvent("OnSessionInit");

    // Track any paid traffic that is not tagged with source.
    if (isPaidTraffic() && !getURLParams().utm_source) {
      trackEvent("PaidTrafficMissingUTMSource");
    }

    updateSessionState("browser", {
      sessionInitiated: new Date().toISOString(),
    });
  }

  const sessionInitiated = new Date(
    getSessionState("browser").sessionInitiated
  );
  const secondsSinceSessionStart = Math.floor(
    (new Date().getTime() - sessionInitiated.getTime()) / 1000
  );

  const leadTrackingProperties = getLeadTrackingProperties();

  trackEvent("OnAppInit", {
    visibilityState: document.visibilityState,
    navigationType: getNavType(),
    secondsSinceSessionStart,

    // This creates a simple session tracker based on the referrer
    // of the current page view. New session = referrer is not from Noom.
    // Likely has edge cases vs using a cookie, but this is consistent
    // with the prior implementation.
    // Read here: https://github.com/noom/airflow-workflows/blob/master/dags/templates/mixpanel/raw_data.mixpanel_landing_and_plan_page_events.js
    new_session: !isInApp() && !isReferrerFromNoom(),

    consent: getConsent(),

    ...leadTrackingProperties,
  });

  // TODO: This should be removed after mid-September 2024. That's when 100% of traffic should be reached.
  if (getRouteId() === routeConstants.clinical) {
    const inEligibleState = getSubdivision() in MedRebootRolloutEligibleStates;
    trackEvent("MedRebootRolloutState", {
      rolledInMedReboot: isEligibleForCompoundedMed(),
      inEligibleState,
      isEligibleForReboot: !!(inEligibleState || isPaidSocialTraffic()),
    });
  }

  // TODO(noom events): this converts the forced plan property keys to match
  // Noom Events. getLeadTrackingProperties should be updated once we are no longer
  // calling `OnAppInit` above.
  if (leadTrackingProperties.forcedNoomPlanId) {
    leadTrackingProperties.forcedPlanDurationMonth =
      leadTrackingProperties.forcedPlanDuration;
    delete leadTrackingProperties.forcedPlanDuration;
  }

  trackBuyflowEvent(
    "BuyflowClientInitialized",
    {
      visibilityState: document.visibilityState,
      navigationType: getNavType(),
      secondsSinceSessionStart,

      // This creates a simple session tracker based on the referrer
      // of the current page view. New session = referrer is not from Noom.
      // Likely has edge cases vs using a cookie, but this is consistent
      // with the prior implementation.
      // Read here: https://github.com/noom/airflow-workflows/blob/master/dags/templates/mixpanel/raw_data.mixpanel_landing_and_plan_page_events.js
      newSession: !isInApp() && !isReferrerFromNoom(),

      consent: getConsent() as string[],

      ...leadTrackingProperties,
    },
    { blockRoutingToMixpanel: true }
  );
}

export function trackAppOpened() {
  const { activeSessionTrackers } = getSessionState("browser");

  const delay = Math.floor(performance.now());
  let sessionTrackers = [];
  if (activeSessionTrackers) {
    sessionTrackers = [...activeSessionTrackers];
  }

  const now = Date.now();
  // If no trackers are active create a suite of new session trackers
  // otherwise use the existing trackers
  if (sessionTrackers.length === 0) {
    sessionTrackers.push({
      eventName: "OnSite5Seconds",
      expiryTime: now + Math.max(5000 - delay, 0),
    });
    sessionTrackers.push({
      eventName: "OnSite30Seconds",
      expiryTime: now + Math.max(30000 - delay, 0),
    });
    sessionTrackers.push({
      eventName: "OnSite90Seconds",
      expiryTime: now + Math.max(90000 - delay, 0),
    });
    // After user being on the buyflow for at least 30 seconds, trigger mixpanel tracking for GoodClick
    // Note: We don't want to take the page load time into account for GoodClick.
    //       That's what the twin `OnSite30Seconds` event is for.
    sessionTrackers.push({
      eventName: "GoodClick",
      expiryTime: now + 30000,
    });
  }

  // Filter out any trackers that have expired
  // and restart the setTimeout for any valid session trackers
  sessionTrackers
    .filter((tracker) => tracker.expiryTime - now >= 0)
    .forEach((tracker) => {
      setTimeout(() => {
        trackEvent(tracker.eventName);
      }, tracker.expiryTime - now);
    });

  // * We don't filter the session trackers in session state so that once the session trackers are created
  // * they will be persisted for the lifetime of the session to prevent duplicate events
  updateSessionState("browser", {
    activeSessionTrackers: sessionTrackers as SessionTrackers,
  });
}

export function trackPageView<K extends keyof NoomBuyflowEvents>(
  pageId: string,
  customBuyflowEvent?: {
    eventName: K | Capitalize<K>;
    eventProps: NoomBuyflowEvents[K];
  }
) {
  trackBuyflowEvent("BuyflowPageViewed", { pageId });

  if (customBuyflowEvent) {
    trackBuyflowEvent(
      customBuyflowEvent.eventName,
      customBuyflowEvent.eventProps
    );
  }
}

export function usePageView<K extends keyof NoomBuyflowEvents>(
  pageId: string,
  customBuyflowEvent?: {
    eventName: K | Capitalize<K>;
    eventProps: NoomBuyflowEvents[K];
  }
) {
  useOnce(() => {
    trackPageView(pageId, customBuyflowEvent);
  });
}
