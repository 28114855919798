import getStore from "src/utils/redux/store";
import { pushReroll, setParam } from "./utils";
import { OffersParams } from "@pageDefinitions/goto/offers";
import { setNoomSessionStorage } from "src/utils/noomSessionStorage";
import { SessionStorageKey } from "src/utils/constants";
import { createForwardParams, getSearchQuery } from "src/utils/urlParams";

import { reroll } from "../components/PageSetRouter";
import { isTelehealth } from "src/utils/userSegment/features";
import goto from "@pageDefinitions/goto/index";
import { telehealthFeedbackLayer } from "@pageDefinitions/goto/constants";
import { getB2BReturnUrl } from "src/components/refactored-survey/question-sets/insurance-survey-questions/utils/getB2BReturnUrl";
import { postMakeEligible } from "src/funnels/insurance-reimbursement/requests/wheelRequestsDeprecated/postMakeEligible";
import { appConfig } from "src/config";

function telehealthPath(
  pathname: string,
  additionalQueryParams?: Record<string, string | number | boolean>
) {
  const store = getStore();
  const state = store.getState();
  const { userData } = state;

  const queryParams = createForwardParams();
  setParam(queryParams, "userId", userData.user_id);

  // eslint-disable-next-line no-restricted-syntax,guard-for-in
  for (const key in additionalQueryParams) {
    if (additionalQueryParams[key]) {
      setParam(queryParams, key, additionalQueryParams[key]);
    }
  }

  return `/telehealth${pathname}${getSearchQuery(queryParams)}`;
}
const telehealthRoot = (
  additionalQueryParams?: Record<string, string | number | boolean>
) => {
  return telehealthPath("/", additionalQueryParams);
};

export default {
  async insurance(isTelehealthBuyflow: boolean) {
    const state = getStore().getState();

    if (isTelehealthBuyflow) {
      setNoomSessionStorage(SessionStorageKey.isTelehealthBuyflow, true);
      await postMakeEligible(state.userData.user_id);
    } else {
      setNoomSessionStorage(SessionStorageKey.isTelehealthBuyflow, false);
    }

    const additionalQueryParams = {
      noomPlanId: state.recommendedPlan.noom_plan_id,
    };
    reroll(`${telehealthRoot(additionalQueryParams)}#/insurance`);
  },

  patientIntake(params?: OffersParams) {
    const additionalQueryParams = params ? { userId: params.userId } : {};
    pushReroll(
      `${telehealthRoot(additionalQueryParams)}#/patientIntake`,
      isTelehealth
    );
  },

  // Allow parameters to goto.telehealthLanding to specify goto= for steadymd
  // users.
  telehealthLanding(gotoPath?: string) {
    reroll(
      `${telehealthRoot()}${gotoPath ? `&goto=${gotoPath}` : ""}#/landing`
    );
  },

  telehealthSchedule() {
    const path = "/schedule/initial_video_visit";
    reroll(telehealthPath(path));
  },

  telehealthReschedule() {
    const path = "/reschedule/initial_video_visit";
    reroll(telehealthPath(path));
  },

  telehealthB2BEnrollmentComplete() {
    reroll(`${telehealthRoot()}#/b2bComplete`);
  },

  async feedback(replaceHistory = false) {
    return goto.pushLayer(telehealthFeedbackLayer, null, replaceHistory);
  },

  // SteadyMD surveys

  async steadyIntake(fromNoomMedOffer = false) {
    const params = new URLSearchParams();
    if (fromNoomMedOffer) {
      params.set("fromNoomMedOffer", "true");
    }
    reroll(`${telehealthRoot()}#/steadyPatientIntake`, params);
  },

  async mdiIntake() {
    reroll(`${telehealthRoot()}#/mdiPatientIntake`);
  },

  async telehealthEnrollmentSetup() {
    reroll(`${telehealthRoot()}#/enrollmentSetup`);
  },

  async labs() {
    reroll(`${telehealthRoot()}#/labs`);
  },

  async pharmacy() {
    reroll(`${telehealthRoot()}#/pharmacy`);
  },

  // V2 New patient Routes
  async insuranceV2() {
    reroll(`${telehealthRoot()}#/v2/insurance`);
  },

  b2bReturnUrl() {
    const url = getB2BReturnUrl();
    if (url) {
      window.location.href = url;
    }
  },

  patientCenterLabs() {
    window.location.href = `${appConfig.PATIENT_PORTAL_URL}documents`;
  },
};
