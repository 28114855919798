export const MAX_COPAY_DEDUCTIBLE_TO_DISPLAY_AMOUNT = 40;

export enum PatientIntakeQuestionType {
  a1c = "a1c",
  address = "address",
  alcoholFrequency = "alcoholFrequency",
  altoPharmacy = "altoPharmacy",
  bariatricSurgery = "bariatricSurgery",
  historyBariatricSurgery = "historyBariatricSurgery",
  bloodPressure = "bloodPressure",
  choosePharmacy = "choosePharmacy",
  conditions = "conditions",
  confirmUser = "confirmUser",
  eatingDisorder = "eatingDisorder",
  familyHistory = "familyHistory",
  heartRate = "heartRate",
  heightWeight = "heightWeight",
  labs = "labs",
  labUploader = "labUploader",
  medication = "medication",
  useMedications = "useMedications",
  medicationAllergies = "medicationAllergies",
  hasMedicationAllergies = "hasMedicationAllergies",
  medicationAllergiesList = "medicationAllergiesList",
  medicationList = "medicationList",
  medications = "medications",
  notAvailable = "notAvailable",
  originalConditions = "originalConditions",
  otherSurgeries = "otherSurgeries",
  hasOtherSurgeries = "hasOtherSurgeries",
  otherSurgeriesList = "otherSurgeriesList",
  patientAcknowledgement = "patientAcknowledgement",
  patientAttestation = "patientAttestation",
  phoneNumber = "phoneNumber",
  pregnantOrBreastfeeding = "pregnantOrBreastfeeding",
  searchPharmacy = "searchPharmacy",
  selfAssessedHealth = "selfAssessedHealth",
  suicideOrSelfHarm = "suicideOrSelfHarm",
  useDrugs = "useDrugs",
  useTobacco = "useTobacco",
  // Below are SteadyMD Intake
  familyHistoryThyroidCancer = "familyHistoryThyroidCancer",
  conditionsExisting = "conditionsExisting",
  bloodPressureSimple = "bloodPressureSimple",
  heartRateSimple = "heartRateSimple",
  lifestyleImpacts = "lifestyleImpacts",
  suicideOrSelfHarm1 = "suicideOrSelfHarm1",
  suicideOrSelfHarm2 = "suicideOrSelfHarm2",
  // SteadyMD Insurance Intake
  insuranceCoverage = "insuranceCoverage",
  insurancePayer = "insurancePayer",
  insuranceIsPrimarySubscriber = "insuranceIsPrimarySubscriber",
  insurancePrimarySubscriberName = "insurancePrimarySubscriberName",
  insurancePrimarySubscriberDob = "insurancePrimarySubscriberDob",
  insuranceMemberId = "insuranceMemberId",
  insuranceGroupId = "insuranceGroupId",
  insuranceRxBin = "insuranceRxBin",
  insuranceRxPcn = "insuranceRxPcn",
  insuranceRxGroup = "insuranceRxGroup",
  // Below are keys that are returned from requiredPatientIntake
  // The discrepancy above is that we may get back keys that dont send
  // i.e. "bmi" but still want to handle as PatientIntakeQuestion types
  bmi = "bmi",
  height = "height",
}

export const enum ConsultType {
  EM = "EM",
  RPM = "RPM",
  VIDEO = "VIDEO",
}

export enum StatesLabsNotIncluded {
  NY = "NY",
  RI = "RI",
  NJ = "NJ",
}

export enum MedRebootRolloutEligibleStates {
  AK = "AK",
  AL = "AL",
  AR = "AR",
  AZ = "AZ",
  CO = "CO",
  GA = "GA",
  HI = "HI",
  IA = "IA",
  ID = "ID",
  IN = "IN",
  KS = "KS",
  KY = "KY",
  ME = "ME",
  MI = "MI",
  MN = "MN",
  MO = "MO",
  NC = "NC",
  ND = "ND",
  NE = "NE",
  NH = "NH",
  NM = "NM",
  NV = "NV",
  OH = "OH",
  OK = "OK",
  OR = "OR",
  RI = "RI",
  SC = "SC",
  SD = "SD",
  TN = "TN",
  UT = "UT",
  VT = "VT",
  WI = "WI",
  WV = "WV",
  WY = "WY",
}

export enum CompoundedPlanIneligibleStates {
  MS = "MS",
  LA = "LA",
}

export enum BrandedPlanIneligibleStates {
  AL = "AL",
  VA = "VA",
}

export enum EligibleStateCodeNoomClinicalLegacy {
  AK = "AK",
  AR = "AR",
  AZ = "AZ",
  CA = "CA",
  CO = "CO",
  CT = "CT",
  DC = "DC",
  DE = "DE",
  FL = "FL",
  GA = "GA",
  HI = "HI",
  IA = "IA",
  ID = "ID",
  IL = "IL",
  IN = "IN",
  KS = "KS",
  KY = "KY",
  LA = "LA",
  MA = "MA",
  MD = "MD",
  ME = "ME",
  MI = "MI",
  MN = "MN",
  MO = "MO",
  MS = "MS",
  MT = "MT",
  NC = "NC",
  ND = "ND",
  NE = "NE",
  NH = "NH",
  NJ = "NJ",
  NM = "NM",
  NV = "NV",
  NY = "NY",
  OH = "OH",
  OK = "OK",
  OR = "OR",
  PA = "PA",
  RI = "RI",
  SC = "SC",
  SD = "SD",
  TN = "TN",
  TX = "TX",
  UT = "UT",
  VT = "VT",
  WA = "WA",
  WI = "WI",
  WV = "WV",
  WY = "WY",
}

export const INELIGIBLE_HEALTH_RISKS = [
  "type1Diabetes",
  "activeHeartDiseaseOrFailure",
  "liverFailure",
  "cancerUnderTreatment",
];

export const BRANDED_PLAN_INELIGIBLE_HEALTH_RISKS = [
  "activeHeartDiseaseOrFailure",
  "liverFailure",
  "cancerUnderTreatment",
];

export const COMPOUNDED_PLAN_INELIGIBLE_HEALTH_RISKS = [
  "pancreatitis",
  "men2",
  "benzylAlcoholAllergy",
];

export const COMPOUNDED_MED_INELIGIBLE_HEALTH_RISKS = ["type1Diabetes"];

export enum SurveyType {
  // create a new consult
  Schedule = "Schedule",
  // move an existing consult to a new time
  Reschedule = "Reschedule",
}

export const TELEHEALTH_ADDON_PREFIX =
  "telehealth-access-self-pay-subscription-";

// When existing users sign up for Noom Med, we want to offer them the 1 month
// 0 day trial plan.
export const NOOM_MED_OFFER_PLAN_ID = `${TELEHEALTH_ADDON_PREFIX}1m-0t`;

export const MDI_CONSENT_URL = "https://mdintegrations.com/tos";
