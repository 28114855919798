import { routeConstants } from "./constants";
import { getMeristemConfigValue } from "./meristemConfig";
import { getRouteId } from "./meristemContext";
import { getUserCurrency } from "./plans";
import { formatPrice } from "./priceUtils";
import { getURLParams } from "./urlParams";
import { isDirect, isEnLanguage, isUS } from "./userSegment";

const BLACK_FRIDAY_PROMO_CODE_3_MONTHS_OFF = "BLACKFRIDAY3";
const BLACK_FRIDAY_PROMO_CODE_6_MONTHS_OFF = "BLACKFRIDAY6";
const NEW_YEAR_PROMO_CODE_3_MONTHS_OFF = "NEWYEAR3";
const NEW_YEAR_PROMO_CODE_6_MONTHS_OFF = "NEWYEAR6";
const SUMMER_PROMO_CODE_6_MONTH_OFF = "SUMMER6";
export type HolidayPromo =
  | typeof BLACK_FRIDAY_PROMO_CODE_3_MONTHS_OFF
  | typeof BLACK_FRIDAY_PROMO_CODE_6_MONTHS_OFF
  | typeof NEW_YEAR_PROMO_CODE_3_MONTHS_OFF
  | typeof NEW_YEAR_PROMO_CODE_6_MONTHS_OFF
  | typeof SUMMER_PROMO_CODE_6_MONTH_OFF;

function isUserSegmentEligible() {
  return isEnLanguage() && !isUS();
}

function isRouteEligible() {
  const routeId = getRouteId();
  return [
    routeConstants.exsf01,
    routeConstants.exsf01d,
    routeConstants.direct,
  ].includes(routeId);
}

export function isNewYearPromotion() {
  const urlParams = getURLParams();
  const promo = urlParams?.promo;

  return (
    isUserSegmentEligible() &&
    isRouteEligible() &&
    ["3MonthNewYear2024", "6MonthNewYear2024"].includes(promo)
  );
}

export function isNewYearPromotionVar2() {
  const urlParams = getURLParams();
  const newYearPromo = urlParams?.newYearPromo;

  return isNewYearPromotion() && newYearPromo === "var2";
}

export function isBlackFridayPromotion() {
  const urlParams = getURLParams();
  const promo = urlParams?.promo;

  return (
    isUserSegmentEligible() &&
    isRouteEligible() &&
    (promo === "3MonthBFCM2023" || (promo === "6MonthBFCM2023" && isUS()))
  );
}

export function isSummerPromotion() {
  const urlParams = getURLParams();
  const promo = urlParams?.promo;

  return (
    isUserSegmentEligible() && isRouteEligible() && promo === "6MonthSummer2024"
  );
}

export function isHolidayPromotion() {
  return (
    isBlackFridayPromotion() || isNewYearPromotion() || isSummerPromotion()
  );
}

export function getHolidayPromocode(): HolidayPromo {
  const forcedPlanDuration = getHolidayPromoForcedPlanDuration();
  if (isBlackFridayPromotion()) {
    return forcedPlanDuration === 12
      ? BLACK_FRIDAY_PROMO_CODE_6_MONTHS_OFF
      : BLACK_FRIDAY_PROMO_CODE_3_MONTHS_OFF;
  }

  if (isSummerPromotion()) {
    return SUMMER_PROMO_CODE_6_MONTH_OFF;
  }

  return forcedPlanDuration === 12
    ? NEW_YEAR_PROMO_CODE_6_MONTHS_OFF
    : NEW_YEAR_PROMO_CODE_3_MONTHS_OFF;
}

export function isHolidayPromocode(
  initialPromocode: string
): initialPromocode is HolidayPromo {
  return [
    BLACK_FRIDAY_PROMO_CODE_3_MONTHS_OFF,
    BLACK_FRIDAY_PROMO_CODE_6_MONTHS_OFF,
    NEW_YEAR_PROMO_CODE_3_MONTHS_OFF,
    NEW_YEAR_PROMO_CODE_6_MONTHS_OFF,
    SUMMER_PROMO_CODE_6_MONTH_OFF,
  ].includes(initialPromocode);
}

export function canApplyHolidayPromocode(promocode: HolidayPromo) {
  if (promocode === BLACK_FRIDAY_PROMO_CODE_6_MONTHS_OFF && !isUS()) {
    return false;
  }

  // Allow users to redeem the promocode on direct in case they come back later
  return isUserSegmentEligible() && (isRouteEligible() || isDirect());
}

export function getHolidayPromoForcedPlanDuration(promocode?: HolidayPromo) {
  if (promocode) {
    return [
      BLACK_FRIDAY_PROMO_CODE_3_MONTHS_OFF,
      NEW_YEAR_PROMO_CODE_3_MONTHS_OFF,
    ].includes(promocode)
      ? 6
      : 12;
  }

  const urlParams = getURLParams();
  const promo = urlParams?.promo;
  if (
    (isUS() && promo === "6MonthBFCM2023") ||
    promo === "6MonthNewYear2024" ||
    promo === "6MonthSummer2024"
  ) {
    return 12;
  }

  return 6;
}

// Hardcoded post-discount prices to avoid having
// to use an api request to load the forced plan before checkout
const priceMapping = {
  USD: {
    6: 159,
    12: 179,
  },
  CAD: {
    6: 129,
    12: 199,
  },
  NZD: {
    6: 169,
    12: 209,
  },
  AUD: {
    6: 149,
    12: 199,
  },
  GBP: {
    6: 59,
    12: 114,
  },
  EUR: {
    6: 99,
    12: 119,
  },
};

const priceMappingDecreased = {
  USD: {
    6: 139,
    12: 159,
  },
  CAD: {
    6: 129,
    12: 199,
  },
  NZD: {
    6: 169,
    12: 209,
  },
  AUD: {
    6: 149,
    12: 199,
  },
  GBP: {
    6: 59,
    12: 114,
  },
  EUR: {
    6: 99,
    12: 119,
  },
};
export function getHolidayPromoForcedPlanPrice() {
  const currency = getUserCurrency();
  const forcedPlanDuration = getHolidayPromoForcedPlanDuration();

  const mapping = getMeristemConfigValue("isPlanPriceDecreaseActive")
    ? priceMappingDecreased
    : priceMapping;

  const rawPrice = mapping[currency]?.[forcedPlanDuration];
  return formatPrice(rawPrice, currency);
}

const promocodeMapping = {
  USD: {
    12: "SUMMERUSD6",
  },
  CAD: {
    12: "SUMMERCAD6",
  },
  NZD: {
    12: "SUMMERNZD6",
  },
  AUD: {
    12: "SUMMERAUD6",
  },
  GBP: {
    12: "SUMMERGBP6",
  },
  EUR: {
    12: "SUMMEREUR6",
  },
};

export function getHolidayReplacementPromocode(
  promocode: HolidayPromo,
  currency: string
) {
  const planDuration = getHolidayPromoForcedPlanDuration(promocode);
  return promocodeMapping[currency]?.[planDuration];
}
