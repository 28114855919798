/* eslint-disable max-classes-per-file */
import Cookies from "js-cookie";
import { ReadonlyDeep } from "type-fest";
import getApiDomain, {
  prepareGrowthAPIParameters,
} from "src/utils/services/api-params";
import { getInAppAutoCookie } from "./authCookieParser";
import { send } from "./fetch";
import { captureException } from "./error";
import type { Context } from "@noom/meristem-context";
import { getSearchQuery } from "./urlParams";
import {
  getLanguage,
  getMeristemContext,
  getRequestMetadata,
  getRouteId,
} from "./meristemContext";
import { isHM, isInApp } from "./userSegment";

import { updateRecommendedPlan } from "./redux/slices/recommendedPlan";
import { updateTrialFromServerContext } from "./redux/slices/trial";
import {
  ServerContextInit,
  ServerContextState,
  updateServerContext,
} from "./redux/slices/serverContext";
import getStore from "./redux/store";
import { NoomError } from "./error/NoomError";
import { pathIsInSubscriptionCancelContext } from "src/hooks/counterOffer";

/**
 * Validates the server context. Returns false if the context is undefined, empty or does not have the critical properties;
 */
export function isServerContextValid(context: ServerContextState) {
  // Protect from undefined toStringed values
  if (context?.user_id === "undefined") {
    return false;
  }

  return !!context?.user_id;
}

/**
 * Look through meristemContext and if certain fields are found, set them in
 * the cookie.
 */
export function setCookiesFromMeristemContext(
  meristemContext: ReadonlyDeep<Context>
) {
  if (meristemContext.route_id) {
    Cookies.set("_routeId", meristemContext.route_id, {
      domain: getApiDomain(),
    });
  }

  if (meristemContext.language_code) {
    Cookies.set("_languageCode", meristemContext.language_code, {
      domain: getApiDomain(),
    });
  }
}

async function fetchContext(
  contextType: string,
  URL: string,
  useInAppAuthCookie: boolean
): Promise<ServerContextState> {
  // Load in-app auth cookies, if available
  let authCookie: Record<string, string>;
  if (useInAppAuthCookie) {
    authCookie = getInAppAutoCookie();
  }

  const method = useInAppAuthCookie ? "POST" : "GET";
  try {
    const context: ServerContextState = await send(method, URL, authCookie);
    context.initiatingContextType = contextType;

    return context;
  } catch (error) {
    captureException(error, [method, "server context fetch detailed"]);

    throw new NoomError("Failed to fetch server context", {
      cause: error,
    });
  }
}

const contextCache: Record<string, Promise<ServerContextState>> = {};

/**
 * Fetch the serverContext from the Growth API.
 * Currently has special handling in app context fetching.
 */
async function getContext() {
  const { context_type: contextType } = getMeristemContext();

  const languageParam = encodeURIComponent(getLanguage());
  const routeIdParam = encodeURIComponent(getRouteId());

  const { pathname, search } = window.location;

  const queryParams = new URLSearchParams(search);
  prepareGrowthAPIParameters(queryParams);

  // WARN: The store is not hydrated with API-sourced data at this point.
  const { recommendedPlan } = getStore().getState();

  let requestPath = pathname.replace(/^\/|\/$/g, "");

  // Skip server context request for minimal contexts
  const isUnknownPPath = pathname.startsWith("/p/");

  const isNoomMedOfferPath =
    pathname.startsWith("/offers/noom-med") ||
    pathname.startsWith("/in-app-offers/noom-med");
  if (
    ["landing", "main-survey", "referral"].includes(contextType) ||
    isUnknownPPath
  ) {
    requestPath = "landing";
  } else if (
    contextType?.startsWith("payment") &&
    // Failover to path context if the recommended plan is not set.
    // This occurs if users hit the paths with noom plan ids embedded in the url.
    recommendedPlan.noom_plan_id
  ) {
    queryParams.set("route", routeIdParam);

    // Don't duplicate path field below
    queryParams.delete("noom_plan_id");

    const planIdParam = encodeURIComponent(recommendedPlan.noom_plan_id);
    if (isHM()) {
      requestPath = `purchase-hm/${planIdParam}`;
    } else {
      requestPath = `purchase/${languageParam}/${planIdParam}`;
    }
  }

  if (/^\/(ps\/)?(probiotics-delivery|zumba-offer).*/.test(pathname)) {
    requestPath = "noom-premium/v2";
  }

  if (/^\/(ps\/)?(zumba-virtual-plus-in-app-upgrade).*/.test(pathname)) {
    requestPath = "offers/v2";
  }

  if (
    contextType === "noom-premium" ||
    contextType === "noom-premium-survey" ||
    contextType === "noom-premium-fiton-survey"
  ) {
    // Note: (pratik) Custom plans uses a different request path to call
    // basic context instead of noom-premium's context. This is a short term
    // fix to avoid an error when upid is not passed from email links. The
    // individual recipe details pages also fall under this cagegory.
    if (
      requestPath.includes("custom-plans") ||
      requestPath.includes("recipe")
    ) {
      requestPath = "noom-premium/custom-plans/v2";
    } else {
      requestPath = "noom-premium/v2";
    }
  }

  if (["noom-summit", "noom-summit-survey"].includes(contextType)) {
    requestPath = "noom-summit";
  }

  // Use v2 routing for /add-ons, and /noom-summit-offer
  if (contextType?.startsWith("offers-consultation-survey")) {
    requestPath = `${isInApp() ? "in-app-offers" : "offers"}/v2`;
  } else if (contextType === "addons") {
    requestPath = `add-ons/v2`;
  } else if (contextType === "noom-summit-offer") {
    requestPath = `offers/v2`;
  } else if (contextType === "noom-med-offer") {
    requestPath = getInAppAutoCookie() ? `in-app-offers/v2` : `offers/v2`;
  } else if (contextType === "fast-forward") {
    requestPath = "fast-forward-trial";
  }

  // Use landing context for /offers and /in-app-offers because users will just land
  // before being redirected to the shopify noom-shop
  if (contextType === "offers" || contextType === "in-app-offers") {
    requestPath = "landing";
  }

  if (isNoomMedOfferPath) {
    queryParams.set("includeTelehealthFields", "true");
    requestPath = getInAppAutoCookie() ? `in-app-offers/v2` : `offers/v2`;
  }

  if (contextType === "program-switch") {
    // all the set has a single context
    requestPath = "switch/hm/context";
  }
  if (contextType === "counter-offer") {
    requestPath = "pre-subscription";
  }
  if (
    contextType === "premium-downgrade" ||
    contextType === "med-downgrade" ||
    pathname.startsWith("/cancel/downgrade")
  ) {
    requestPath = "cancel";
  }
  if (pathIsInSubscriptionCancelContext(pathname)) {
    requestPath = "cancel";
  }
  if (contextType === "addons-cancel") {
    requestPath = "cancel/addons";
  }
  if (contextType === "in-app-employer-survey") {
    requestPath = "in-app-employer-survey";
  }
  if (pathname.includes("/trial-check-in")) {
    requestPath = "in-app-basic";
  }

  const pathParts = [requestPath, getSearchQuery(queryParams)].filter(Boolean);

  const URL = `/api/context/v2/${pathParts.join("/")}`;
  const isCached = !!contextCache[URL];

  if (!isCached) {
    const useInAppAuthCookie =
      requestPath.includes("in-app-offers") ||
      requestPath.includes("course") ||
      requestPath.includes("in-app-employer-survey") ||
      requestPath.includes("in-app-basic");

    if (requestPath === "landing") {
      contextCache[URL] = Promise.resolve(landingContext());
    } else {
      contextCache[URL] = fetchContext(contextType, URL, useInAppAuthCookie);
    }
  }
  return { context: await contextCache[URL], isCached };
}

function landingContext(): ServerContextState {
  const { userState } = getRequestMetadata();

  return {
    user_id: userState.userId,
    initiatingContextType: getMeristemContext().context_type,
  };
}

export async function loadServerContext() {
  const { context, isCached } = await getContext();

  // Only update the store if if it is a brand new context or if
  // the current meristem context type has changed (i.e. landing -> payment, etc)
  // from what has been loaded.
  //
  // This is a little hacky, but we want to avoid rebuilding the context
  // (which will overwrite any promo codes from recommendedPlan) unless
  // it has changed.
  if (
    !isCached ||
    context.initiatingContextType !==
      getStore().getState().serverContext.initiatingContextType
  ) {
    (window as any).serverContext = context;
    updateStoreWithServerContext(context);
  }

  return getStore().getState().serverContext;
}

/**
 * After we fetch serverContext, Take the data and update the appropriate
 * redux slices with it.
 */
export function updateStoreWithServerContext(serverContext: ServerContextInit) {
  const store = getStore();
  store.dispatch(updateServerContext(serverContext));
  store.dispatch(updateTrialFromServerContext(serverContext));

  if (serverContext.plan) {
    store.dispatch(
      updateRecommendedPlan({
        // Server may or may not attach the noom plan id to the plan response.
        // Provide the server context copy in the event we do not have one.
        noom_plan_id: serverContext.noom_plan_id,
        ...serverContext.plan,
      })
    );
  }
}
