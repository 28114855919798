import { RecommendedPlanState } from "@utils/redux/slices/recommendedPlan";

import { trackEvent } from "src/utils/api/tracker";
import { acceptedNoomClinicalOffer } from "src/utils/userSegment/features";

import { getTrialInfo } from "./trialFee";

export const decoratePurchaseRequestWithProductCatalog = (
  purchaseRequest: JsonObject,
  recommendedPlan: RecommendedPlanState
) => {
  const { trial_fee, has_trial } = recommendedPlan;
  const trialSaleItem = recommendedPlan?.trial_item_options?.find(
    (trial) => trial.price === trial_fee
  );
  const trialSaleItemId = trialSaleItem?.trial_sale_item_id;

  // equality comparison coerces null<->undefined
  if (
    !acceptedNoomClinicalOffer() &&
    has_trial &&
    trial_fee != null &&
    !trialSaleItemId
  ) {
    trackEvent("ProductAsPlanTrialMismatch", {
      recommendedPlanTrialOptions: recommendedPlan.trial_item_options,
      trialFee: trial_fee,
    });
    throw new Error("ProductAsPlanTrialMismatch");
  }

  const { hasTrial, trackingData } = getTrialInfo(recommendedPlan);
  if (trialSaleItemId && !hasTrial) {
    trackEvent("ProductAsPlanMissingLegacyTrial", {
      recommendedPlan,
      trialInfo: trackingData,
    });
    throw new Error("ProductAsPlanMissingLegacyTrial");
  }

  let newPurchaseRequest = {
    ...purchaseRequest,
    subscriptionSaleItemId: recommendedPlan?.sale_item_id,
  };

  if (hasTrial) {
    newPurchaseRequest = {
      ...newPurchaseRequest,
      trialSaleItemId,
      trialFee: recommendedPlan.trial_fee,
    };
  }
  return newPurchaseRequest;
};
