/* eslint-disable no-restricted-globals */

import {
  landingLayer,
  mainSurveyLayer,
  paymentSurveyLayer,
  paymentCheckoutLayer,
} from "@pageDefinitions/goto/constants";
import { captureException } from "src/utils/error";
import goto from ".";
import {
  isInsideAndroidWebView,
  isInsideIOSWebKit,
  pageSetPushReroll,
} from "./utils";
import {
  getAndroidMessageHandlers,
  getIOSMessageHandlers,
} from "src/utils/in-app/messageHandlers";
import {
  getHmRouteId,
  getMeristemContext,
  getRouteId,
} from "@utils/meristemContext";
import plans from "@utils/plansMapping";
import { isHM } from "src/utils/userSegment";
import { updateForwardedParams, updateURLParams } from "src/utils/urlParams";
import { reroll } from "../components/PageSetRouter";
import { routeConstants } from "src/utils/constants";

export function isBuyflow() {
  return ["landing", "main-survey"].includes(getMeristemContext().context_type);
}

export const linkTo = {
  mainSurvey() {
    return "/ps/main-survey";
  },
};

const routeSwitch = (currentRoute: string, newRoute: string) => {
  if (currentRoute !== newRoute) {
    // Ensure that if we come back, the route is preserved
    updateURLParams({ route: currentRoute });
    updateForwardedParams({ route: newRoute });
  }
};

const hmRouteSwitch = (
  currentRoute: string = getRouteId(),
  newRoute: string = getHmRouteId()
) => {
  routeSwitch(currentRoute, newRoute);
};

export default {
  // Landing
  landing() {
    goto.layer(landingLayer);
  },
  hmLanding() {
    hmRouteSwitch();
    reroll(`/programs/mood/${getHmRouteId()}`);
  },
  // Survey
  async mainSurvey(
    replaceHistory = false,
    routeId?: string,
    entryPoint?: string
  ) {
    return goto.layer(
      mainSurveyLayer,
      null,
      replaceHistory,
      routeId,
      entryPoint
    );
  },
  // Survey
  async paymentSurvey(replaceHistory = false, routeId?: string) {
    return goto.layer(paymentSurveyLayer, null, replaceHistory, routeId);
  },

  // NW payment checkout
  async paymentCheckout(noomPlanId: string) {
    updateURLParams({
      noom_plan_id: noomPlanId,
    });
    updateForwardedParams({
      noom_plan_id: plans["3-7-DAY-TRIAL"],
    });

    routeSwitch(getRouteId(), routeConstants.direct);
    return goto.layer(paymentCheckoutLayer);
  },

  // HM Survey
  async hmMainSurvey() {
    hmRouteSwitch();
    return goto.mainSurvey();
  },

  async hwDirectMainSurvey(entryPoint?: string) {
    updateURLParams({ route: getRouteId() });
    return goto.mainSurvey(false, routeConstants.direct, entryPoint);
  },

  async hwDirectPaymentSurvey() {
    updateURLParams({ route: getRouteId() });
    return goto.paymentSurvey(false, routeConstants.direct);
  },

  /**
   * This is intended mostly as a stopgap and may not work for all future implementations.
   * @deprecated Use page set navigation instead.
   */
  async surveyQuestion(question: string) {
    if (!question) {
      goto.mainSurvey();
      return;
    }

    pageSetPushReroll(
      isHM() ? `/survey/hm/${question}` : `/survey/${question}`
    );
  },

  async choosePlan() {
    pageSetPushReroll(isHM() ? "/survey/hm/choosePlan" : "/survey/choosePlan");
  },

  nativeBuyflowExit() {
    if (isInsideIOSWebKit()) {
      try {
        const messageHandlers = getIOSMessageHandlers();
        messageHandlers?.buyflowExit?.postMessage({});
        return true;
      } catch (err) {
        // istanbul ignore next Error handling
        captureException(err, "nativeBuyflowExit.ios");
      }
    }
    if (isInsideAndroidWebView()) {
      try {
        const messageHandlers = getAndroidMessageHandlers();
        if (messageHandlers && messageHandlers.buyflowExit) {
          messageHandlers.buyflowExit();
          return true;
        }
      } catch (err) {
        // istanbul ignore next Error handling
        captureException(err, "nativeBuyflowExit.android");
      }
    }

    // eslint-disable-next-line no-console
    console.error("nativeBuyflowExit: no message handler found");

    // go to the landing page in case native buyflow exit fails
    goto.landing();
    return false;
  },
  nativeWebviewExit() {
    if (isInsideIOSWebKit()) {
      const webviewExit = getIOSMessageHandlers()?.webviewExit;
      webviewExit?.postMessage({});
      return !!webviewExit;
    }
    if (isInsideAndroidWebView()) {
      const webviewExit = getAndroidMessageHandlers()?.webviewExit;
      if (webviewExit) {
        webviewExit();
      }
      return !!webviewExit;
    }
    return false;
  },
  closeInAppContentScreen() {
    // score=1 will mark the in-app task as complete
    window.location.href = "https://unknown.noom.com?score=1&close=1";
  },
  closeInAppWebview() {
    // this is different from the above as it just closes the webview without marking a task as complete
    window.location.href = "https://unknown.noom.com/?close=1";
  },
};
