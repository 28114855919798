import i18n from "src/locales";
import type { PaymentAddon } from "src/utils/redux/slices/paymentAddon";
import { getMeristemConfigValue } from "./meristemConfig";
import { isCheckoutOfferTimerExpired } from "@utils/checkoutTimer";
import {
  isDECoursePackEligible,
  shouldShowCoursePackCheckoutOffers,
  isEligibleForNX1CourseEnhancements,
  isEligibleForPersonalizedPlanTimer,
  isEligibleForFreeAgingMetabolismOffer,
  shouldSeeGlp1Companion,
  isRouteEligibleForGLP1CEOfferOnly,
} from "./userSegment/features";
import { trackEvent } from "./api/tracker";
import getStore from "./redux/store";
import { TELEHEALTH_ADDON_PREFIX } from "@components/refactored-survey/question-sets/insurance-survey-questions/utils/insuranceConstants";
import { COURSE_ENHANCEMENT_DATA_MAP } from "src/utils/courseEnhancementData";

/* The utility functions in this file allow us to make changes to the course pack through the meristemConfig json
in order to enable "pseudo experiments" without consuming traffic. These pseudoexperiments can target any context in Meristem, and
the meristemConfig flags will propogate through the entire user journey to enable these course pack changes.

You will need to set up the meristemConfig to look something like this:

Control branch:

{
  "coursePackExperimentName": "exXXX",
  "coursePackExperimentVariation": "control"
}

Variation Branch:

{
  "coursePackExperimentName": "exXXX",
  "coursePackExperimentVariation": "variation",
  "coursePackProductId": "nw-plus-xxx", // New addon product id that will be used in the experiment
  "coursePackProductTitle": "Some title" // New addon product title that will be used in the experiment for contexts before payment-checkout where the add-on is not loaded yet
}

*/

const DEFAULT_PRODUCT_ID = "nw-plus-ex97-baseline";
export const FREE_COURSE_PACK_OFFER_PRODUCT_ID = "nw-plus-free-baseline";

const DEFAULT_CHANNEL_ID = "hw-course-add-ons-baseline";
const DEFAULT_CLINICAL_CHANNEL_ID = "noom-clinical-baseline";

export const MW_PRODUCT_ID = "nw-plus-ex97-ups_ex281_paid";
export const AM_PRODUCT_ID = "aging-and-metabolism-coursepack";
export const SLEEP_PRODUCT_ID = "sleep-coursepack-ups_ex281_paid";
export const MOVEMENT_PRODUCT = "movement-coursepack-ups_ex281_paid";

export const FREE_MW_OFFER_PRODUCT_ID = "nw-plus-ex97-ups_ex281_free";
export const FREE_AM_OFFER_PRODUCT_ID =
  "aging-and-metabolism-coursepack-ups_ex281_free";
export const FREE_SLEEP_PRODUCT_ID = "sleep-coursepack-ups_ex281_free";
export const FREE_MOVEMENT_PRODUCT = "movement-coursepack-ups_ex281_free";

const CHANNEL_ID_UPS_EX281 = "hw-course-add-ons-ups_ex281";

export enum paymentAddonProducts {
  mentalWellness = "mentalWellnessCoursepack",
  agingMetabolism = "agingMetabolismCoursepack",
  sleep = "sleep",
  movement = "movement",
  noomClinical = "noomClinical",
  glp1Companion = "glp1CompanionCoursepack",
}

export const getPaymentAddonOfferChannels = (): string[] => {
  return [
    isEligibleForNX1CourseEnhancements()
      ? CHANNEL_ID_UPS_EX281
      : DEFAULT_CHANNEL_ID,
    DEFAULT_CLINICAL_CHANNEL_ID,
  ];
};

export const getNoomClinicalProductId = () => {
  const { recommendedPlan } = getStore().getState();

  const planLength = recommendedPlan.duration_in_months;

  // Note (Vitza): Add-on trial durations are defined with an offset of 1.
  const trialKey = 31;

  const planName = `${TELEHEALTH_ADDON_PREFIX}${planLength}m-${trialKey}t`;
  if (planName === `${TELEHEALTH_ADDON_PREFIX}1m-15t`) {
    return `${TELEHEALTH_ADDON_PREFIX}telex171-1m-15t`;
  }
  return planName;
};

export function getMWPaidCoursepackId() {
  if (isDECoursePackEligible()) {
    return `${DEFAULT_PRODUCT_ID}-de`;
  }

  let paidMWProductId;
  if (isEligibleForNX1CourseEnhancements()) {
    paidMWProductId = MW_PRODUCT_ID;
  } else {
    paidMWProductId = DEFAULT_PRODUCT_ID;
  }
  const productId: string =
    getMeristemConfigValue("coursePackProductId") || paidMWProductId;

  return productId;
}

/** Mental Wellness Coursepack */
export const getMWCoursepackId = () => {
  if (isDECoursePackEligible()) {
    return `${DEFAULT_PRODUCT_ID}-de`;
  }

  let freeMWProductId;
  let paidMWProductId;
  if (isEligibleForNX1CourseEnhancements()) {
    freeMWProductId = FREE_MW_OFFER_PRODUCT_ID;
    paidMWProductId = MW_PRODUCT_ID;
  } else {
    freeMWProductId = FREE_COURSE_PACK_OFFER_PRODUCT_ID;
    paidMWProductId = DEFAULT_PRODUCT_ID;
  }
  const productId: string =
    getMeristemConfigValue("coursePackProductId") || freeMWProductId;

  if (productId === freeMWProductId) {
    return isCheckoutOfferTimerExpired() ? paidMWProductId : freeMWProductId;
  }

  return productId;
};

/** Aging and Metabolism Coursepack */
export const getAMCoursepackId = () => {
  if (!isEligibleForNX1CourseEnhancements()) {
    return "aging-and-metabolism-coursepack";
  }
  return !isEligibleForFreeAgingMetabolismOffer() ||
    isCheckoutOfferTimerExpired()
    ? AM_PRODUCT_ID
    : FREE_AM_OFFER_PRODUCT_ID;
};

/** Sleep Coursepack */
export const getSleepCoursepackId = () => {
  return isCheckoutOfferTimerExpired()
    ? SLEEP_PRODUCT_ID
    : FREE_SLEEP_PRODUCT_ID;
};

/** Movement Coursepack */
export const getMovementCoursepackId = () => {
  return isCheckoutOfferTimerExpired()
    ? MOVEMENT_PRODUCT
    : FREE_MOVEMENT_PRODUCT;
};

/** GLP-1 Companion Coursepack */
export const getGlp1CoursepackId = () => "glp1-companion-baseline";

export const getPaymentAddonProduct = (paymentAddon?: PaymentAddon) => {
  const productId = paymentAddon?.addon_product_id;
  if (productId?.startsWith("nw-plus-")) {
    return paymentAddonProducts.mentalWellness;
  }
  if (productId?.startsWith("aging-and-metabolism-")) {
    return paymentAddonProducts.agingMetabolism;
  }
  if (productId?.startsWith("sleep")) {
    return paymentAddonProducts.sleep;
  }
  if (productId?.startsWith("movement")) {
    return paymentAddonProducts.movement;
  }
  if (productId?.startsWith(TELEHEALTH_ADDON_PREFIX)) {
    return paymentAddonProducts.noomClinical;
  }
  if (productId?.startsWith("glp1-companion")) {
    return paymentAddonProducts.glp1Companion;
  }
  return null;
};

export type PaymentAddonProduct = ReturnType<typeof getPaymentAddonProduct>;

// NOTE: Make sure to add to CourseEnhancementProduct also if adding to this function
export const getCourseEnhancementProducts = () => {
  // whitelist for course enhancement ids to distinguish from other addons such as
  // noom clinical, etc

  if (!shouldShowCoursePackCheckoutOffers()) {
    return [];
  }

  if (isRouteEligibleForGLP1CEOfferOnly()) {
    if (shouldSeeGlp1Companion()) {
      return [paymentAddonProducts.glp1Companion];
    }
    return [];
  }

  if (!isEligibleForNX1CourseEnhancements()) {
    return [paymentAddonProducts.mentalWellness];
  }

  return [
    paymentAddonProducts.agingMetabolism,
    paymentAddonProducts.mentalWellness,
    paymentAddonProducts.sleep,
    paymentAddonProducts.movement,
    shouldSeeGlp1Companion() && paymentAddonProducts.glp1Companion,
  ].filter(Boolean);
};

export const getNonSubsurveyProducts = () => {
  // blacklist for course enhancements that are not part of the payment-survey-nm-bundle layer
  return [paymentAddonProducts.glp1Companion];
};

export type CourseEnhancementProduct =
  | paymentAddonProducts.agingMetabolism
  | paymentAddonProducts.mentalWellness
  | paymentAddonProducts.sleep
  | paymentAddonProducts.movement
  | paymentAddonProducts.glp1Companion;

export const getPaymentAddonTitle = (paymentAddon?: PaymentAddon) => {
  if (paymentAddon?.title) {
    return paymentAddon.title;
  }
  // Allow using a title from meristem config in case we need it earlier in the buyflow
  // when the paymentAddon is not loaded yet and we don't want to make an extra API call
  const coursePackProductTitle = getMeristemConfigValue(
    "coursePackProductTitle"
  );
  return coursePackProductTitle || i18n.t("price-breakdown:coursePackTitle");
};

export const getCourseEnhancementTitle = (
  paymentAddon?: PaymentAddon,
  titleKey?: string
) => {
  const product = getPaymentAddonProduct(paymentAddon);
  const i18nNamespace = COURSE_ENHANCEMENT_DATA_MAP[product]?.i18nNamespace;
  return i18nNamespace && titleKey
    ? i18n.t(`${i18nNamespace}:${titleKey}`)
    : getPaymentAddonTitle(paymentAddon);
};

// By default this is called from the checkout page, but if any changes are made earlier then the call to this function will need to be moved
export const maybeTrackCoursePackExperimentStarted = () => {
  const coursePackExperimentName = getMeristemConfigValue(
    "coursePackExperimentName"
  );

  const coursePackExperimentVariation = getMeristemConfigValue(
    "coursePackExperimentVariation"
  );

  if (
    coursePackExperimentName &&
    coursePackExperimentVariation &&
    shouldShowCoursePackCheckoutOffers()
  ) {
    trackEvent("CoursePackHoldoutExperimentStarted", {
      experimentName: coursePackExperimentName,
      experimentVariation: coursePackExperimentVariation,
    });
  }
};

export function getCoursePackTextPrefix(basePrefix) {
  if (isEligibleForPersonalizedPlanTimer()) {
    return basePrefix;
  }
  if (isEligibleForNX1CourseEnhancements()) {
    return `${basePrefix}WithCourseEnhancements`;
  }
  return `${basePrefix}WithNMCoursepack`;
}

/**
 * Not all paymentAddons should be shown to the user,
 * e.g. a Noom Med subscription that doesn't match the duration of the user's HW program
 * or the paid Mental Wellness coursepack when the user is eligble for the free one.
 */
// Note(kevinh): need the paid versions of products here to make sure the crossed out full price
// contains tax when showing users free offers
export const isShowablePaymentAddon = (addon: PaymentAddon) =>
  addon.addon_product_id === getNoomClinicalProductId() ||
  addon.addon_product_id === getMWCoursepackId() ||
  addon.addon_product_id === getAMCoursepackId() ||
  addon.addon_product_id === getSleepCoursepackId() ||
  addon.addon_product_id === getMovementCoursepackId() ||
  addon.addon_product_id === MW_PRODUCT_ID ||
  addon.addon_product_id === AM_PRODUCT_ID ||
  addon.addon_product_id === SLEEP_PRODUCT_ID ||
  addon.addon_product_id === MOVEMENT_PRODUCT;
