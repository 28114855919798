import { useBillingForm } from "src/hooks/billing-info";
import { useEnrollmentForm } from "src/hooks/enrollment";
import { useCallbackRef } from "src/hooks/lifecycle";
import { useCheckout, usePromoCode } from "src/hooks/redux";
import { trackEvent } from "src/utils/api/tracker";
import { CheckoutState, paymentTypes } from "src/utils/redux/slices/checkout";
import { PromoCodeState } from "src/utils/redux/slices/promoCode";
import {
  shouldEnrollUser,
  isInAppNativePurchaseEligible,
} from "src/utils/userSegment/features";

export function usePaymentTracker() {
  const checkout = useCheckout();
  const promoCode = usePromoCode();

  const { billingName } = useBillingForm();
  const { enrollmentInfo } = useEnrollmentForm();

  function trackPaymentSubmit() {
    // Emitting to avoid breaking existing analytics
    trackEvent("clickedSaveProfile");
    trackEvent("PaymentSubmitButtonClicked", {
      paymentMethod: getPaymentMethod(checkout, promoCode),
    });

    // Track user name differing from billing name only if US and not in app
    if (
      shouldEnrollUser() &&
      billingName?.trim() !== enrollmentInfo.name.trim()
    ) {
      trackEvent("UserNameDifferent");
    }
  }
  return {
    trackPaymentSubmit: useCallbackRef(trackPaymentSubmit),
  };
}

export function getPaymentMethod(
  checkout: CheckoutState,
  promoCode: PromoCodeState
) {
  if (isInAppNativePurchaseEligible()) {
    return "native";
  }
  if (promoCode.promoCodeIsVip) {
    return "vip";
  }
  if (checkout.paymentType === paymentTypes.PAYPAL) {
    return "paypal";
  }
  if (checkout.paymentType === paymentTypes.SEPA) {
    return "sepa";
  }
  if (checkout.walletPay && checkout.applePay) {
    return "applePay";
  }
  if (checkout.walletPay && checkout.googlePay) {
    return "googlePay";
  }

  return "creditCard";
}
