import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useCallbackRef } from "src/hooks/lifecycle";
import { compassColors } from "src/utils/styling";
import { announceNode, Announcer } from "../accessibility/Announcer";
import uuid from "uuid";

// Message nodes can have their content announced to screen readers while container nodes won't
type NodeType = "container" | "message";

const ErrorContext = createContext<{
  scrollToError: (
    focusOnFirstInput?: boolean,
    announceErrors?: boolean
  ) => void;
  onError: (node: HTMLElement, type: NodeType) => void;
  onClear: (node: HTMLElement, type: NodeType) => void;
}>(null);

const sortNodes = (nodes: HTMLElement[]) => {
  return nodes.sort((aNode, bNode) => {
    const aRect = aNode.getBoundingClientRect();
    const bRect = bNode.getBoundingClientRect();
    if (aRect.top === bRect.top) {
      return aRect.left - bRect.left;
    }
    return aRect.top - bRect.top;
  });
};

const useErrorNodes = () => {
  const [errorNodes, setErrorNodes] = useState<HTMLElement[]>([]);
  const removeErrorNode = useCallbackRef((error) => {
    setErrorNodes((currentNodes) => {
      return currentNodes.filter((node) => node !== error);
    });
  });

  const addErrorNode = useCallbackRef((add) => {
    setErrorNodes((currentNodes) => {
      if (!currentNodes.includes(add)) {
        return [...currentNodes, add];
      }
      return currentNodes;
    });
  });

  return { removeErrorNode, addErrorNode, errorNodes };
};

export function ErrorMessageProvider({ children }: { children: ReactNode }) {
  const {
    errorNodes: containerNodes,
    addErrorNode: addContainerNode,
    removeErrorNode: removeContainerNode,
  } = useErrorNodes();
  const {
    errorNodes: messageNodes,
    addErrorNode: addMessageNode,
    removeErrorNode: removeMessageNode,
  } = useErrorNodes();

  const onClear = useCallbackRef((node: HTMLElement, type: NodeType) => {
    if (type === "message") {
      removeMessageNode(node);
    } else {
      removeContainerNode(node);
    }
  });

  const onError = useCallbackRef((node: HTMLElement, type: NodeType) => {
    if (type === "container" && !containerNodes.includes(node)) {
      addContainerNode(node);
    }
    if (type === "message" && !messageNodes.includes(node)) {
      addMessageNode(node);
    }
  });

  const announceErrorsNodes = useCallbackRef(() => {
    // There are a couple of reasons why the errors are announced this way instead of using the Announcer component
    // 1. Functionally announcing these errors allows us to ensure they get announced after we finish focusing on an input.
    //    Otherwise, screen readers might start talking about the input that got focused and interrupt these error announcements
    // 2. The Announcer component generally only announces its contents once, but here we want to announce the errors
    //    every time the user tries to submit and fails.
    const sortedNodes = sortNodes(messageNodes);
    sortedNodes.forEach((node) => announceNode(node));
  });

  const scrollToError = useCallbackRef(
    (focusOnFirstInput: boolean, announceErrors: boolean) => {
      // Defer until the render has settled.
      setTimeout(async () => {
        // Sort error nodes left-to-right, top-to-bottom
        const sortedNodes = sortNodes(containerNodes.concat(messageNodes));

        const inputElements = sortedNodes
          .map((node) => node.querySelector("select,input,textarea"))
          .filter(Boolean) as
          | HTMLSelectElement[]
          | HTMLInputElement[]
          | HTMLTextAreaElement[];
        const firstInput = inputElements[0];

        // Focus on upper-left most input
        if (firstInput && focusOnFirstInput) {
          firstInput.focus({ preventScroll: true });

          // Manually scroll to the input element since the focus operation doesn't
          // scroll to iframes or select elements properly
          // The timeout is necessary to prevent the scroll from being interrupted by the focus operation
          // This seems to happen specifically when we focus on an input within an iframe.
          await new Promise<void>((resolve) =>
            setTimeout(() => {
              firstInput.scrollIntoView({
                block: "center",
                behavior: "smooth",
              });
              resolve();
            }, 100)
          );
        } else {
          // Scroll to first error node if there aren't any input errors
          const firstNode = sortedNodes[0];
          if (firstNode) {
            const rect = firstNode.getBoundingClientRect();
            window.scrollBy({
              // Scroll up slightly to provide the user more context.
              top: rect.top - 50,
              behavior: "smooth",
            });
          }
        }
        if (announceErrors) {
          announceErrorsNodes();
        }
      }, 100);
    }
  );

  const context = useMemo(
    () => ({
      scrollToError,
      onClear,
      onError,
    }),
    [scrollToError, onClear, onError]
  );

  return (
    <ErrorContext.Provider value={context}>{children}</ErrorContext.Provider>
  );
}

export function useGeneratedErrorMessageId() {
  return useMemo(() => `${uuid.v4()}-error-message`, []);
}

export function ErrorMessage({
  children,
  errorId,
  className,
  announceError = true,
}: {
  children: ReactNode;
  errorId?: string;
  className?: string;
  announceError?: boolean;
}) {
  const { ref } = useErrorDisplay({ type: "message" });
  const body = (
    <p
      ref={ref}
      css={{ color: compassColors.tarocco }}
      className={className}
      data-cy="error-message"
      id={errorId}
    >
      {children}
    </p>
  );

  if (announceError) {
    return <Announcer announcementStyle="alert">{body}</Announcer>;
  }

  return body;
}

export function useErrorManager() {
  return useContext(ErrorContext);
}

export function useErrorDisplay({
  errored = true,
  type = "container",
}: {
  errored?: boolean;
  type?: NodeType;
}) {
  const lastNode = useRef<HTMLElement>(null);
  const { onError, onClear } = useErrorManager();

  useEffect(() => {
    if (lastNode.current) {
      if (errored) {
        onError(lastNode.current, type);
      } else {
        onClear(lastNode.current, type);
      }
    }
  }, [errored, onClear, onError, type]);

  function ref(el: HTMLElement) {
    if (el && errored) {
      onError(el, type);
    } else {
      onClear(lastNode.current, type);
    }

    lastNode.current = el;
  }

  return {
    ref: useCallbackRef(ref),
  };
}
