import { createSelector } from "@reduxjs/toolkit";
import { AdditionalErrorFields } from "src/components/core/AddressForm/AddressForm";
import { CoreReduxState } from "../../internal";
import { getStateCode, StateCode } from "src/utils/usStates";
import { BillingAddress } from "src/utils/redux/slices/checkout";
import { getNoomClinicalEligibleStates } from "src/utils/userSegment/features";

export const selectResolvedPhysicalAddress = createSelector(
  (state: CoreReduxState) => state.checkout,
  (state: CoreReduxState) => state.checkoutPhysicalAddress,
  (checkoutState, physicalAddressState): BillingAddress => {
    let retAddress = physicalAddressState.address;
    if (physicalAddressState.isSameAsBillingAddress) {
      const { billingAddress } = checkoutState;
      retAddress = {
        ...billingAddress,
      };
    }
    return retAddress;
  }
);

// The backend requires us to pass in a shortened region name eg TX instead of Texas for the physical address
export const selectResolvedPhysicalAddressShortRegion = createSelector(
  selectResolvedPhysicalAddress,
  (address): Omit<BillingAddress, "region"> & { region: StateCode } => {
    return {
      ...address,
      region: getStateCode(address.region),
    };
  }
);

export const validatePhysicalAddressEligibility = createSelector(
  selectResolvedPhysicalAddressShortRegion,
  (address): AdditionalErrorFields => {
    // Noom Med is available only in the US
    if (address.country !== "US") {
      return { country: {} };
    }
    if (!(address.region in getNoomClinicalEligibleStates())) {
      //
      return { region: {} };
    }
    return null;
  }
);

export const alreadyAcceptedSuggestionModal = createSelector(
  (state: CoreReduxState) => state.checkoutPhysicalAddress,
  ({ suggestedAddress, addressInvalid }) => {
    // If there is a suggested address but the address is not marked invalid, then the user accepted
    // either the suggestion or chose to continue with their accepted address.
    // Note that this is the only path wherein the user can use an address other than the one the TR validator suggested
    return (
      suggestedAddress &&
      Object.keys(suggestedAddress).length > 0 &&
      !addressInvalid
    );
  }
);
