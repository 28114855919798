import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import i18n from "src/locales";
import { errorConstants } from "src/utils/services/PurchaseErrorMessages";
import { AppDispatch } from "../internal";
import {
  failCountryValidation,
  failStateValidation,
} from "./checkoutPhysicalAddress/action-creators";
import {
  closeIneligibleAgeModal,
  closeIneligibleMedicareModal,
  failMedicareStatusValidation,
  failNoomClinicalAgeValidation,
  passMedicareStatusValidation,
  validateMedicareStatus,
} from "./paymentEnrollmentFormActions";

export type EnrollmentErrors = {
  name?: boolean | string;
  email?: boolean | string;
  password?: boolean | string;
  confirmPassword?: boolean | string;
  phoneNumber?: boolean | string;
  firstName?: boolean | string;
  lastName?: boolean | string;
  dateOfBirth?: boolean | string;
};

export interface PaymentEnrollmentFormState {
  hasErrors: boolean;
  enrollmentErrors: EnrollmentErrors;
  termAgreementError?: string;
  enrollmentInfo: {
    name: string;
    nameChangeMade: boolean;
    email: string;
    password: string;
    confirmPassword: string;
    phoneNumber: string;
    phoneNumberCountryCode: string;
    termAgreement: boolean;
    secondaryUserSameAddressConfirmation: boolean;
    firstName: string;
    lastName: string;
    dateOfBirth: string;
    smsConsent: boolean;
  };
  showInvalidAgeModal: boolean;
  showMedicareModal: boolean;
  showInvalidMedicareModal: boolean;
  hasMedicare?: boolean;
}

const initialState: PaymentEnrollmentFormState = {
  enrollmentErrors: {},
  enrollmentInfo: {
    nameChangeMade: false,
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    phoneNumber: "",
    phoneNumberCountryCode: "",
    termAgreement: false,
    secondaryUserSameAddressConfirmation: false,
    firstName: "",
    lastName: "",
    dateOfBirth: "",
    smsConsent: false,
  },
  hasErrors: true,
  showInvalidAgeModal: false,
  showMedicareModal: false,
  showInvalidMedicareModal: false,
};

const paymentEnrollmentFormSlice = createSlice({
  name: "paymentEnrollmentForm",
  initialState,
  reducers: {
    updatePaymentEnrollmentFormState(
      state,
      action: PayloadAction<
        Partial<Omit<PaymentEnrollmentFormState, "hasErrors">>
      >
    ) {
      const newState = { ...state, ...action.payload };
      const hasErrors = Object.keys(newState.enrollmentErrors).some(
        (key) => newState.enrollmentErrors[key]
      );
      newState.hasErrors = hasErrors;
      return newState;
    },
    setError(state, action: PayloadAction<EnrollmentErrors>) {
      return {
        ...state,
        enrollmentErrors: { ...state.enrollmentErrors, ...action.payload },
      };
    },
    setTermsError(state, action: PayloadAction<string>) {
      return {
        ...state,
        termAgreementError: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        // If the user tries to purchase with the Noom Med addon but can't due to location,
        // they must check the terms again.
        failCountryValidation,
        (state): PaymentEnrollmentFormState => ({
          ...state,
          enrollmentInfo: {
            ...state.enrollmentInfo,
            termAgreement: false,
          },
        })
      )
      .addCase(
        // Same reason as above.
        failStateValidation,
        (state): PaymentEnrollmentFormState => ({
          ...state,
          enrollmentInfo: {
            ...state.enrollmentInfo,
            termAgreement: false,
          },
        })
      )
      .addCase(
        failNoomClinicalAgeValidation,
        (state): PaymentEnrollmentFormState => ({
          ...state,
          showInvalidAgeModal: true,
          enrollmentInfo: {
            ...state.enrollmentInfo,
            termAgreement: false,
          },
        })
      )
      .addCase(
        closeIneligibleAgeModal,
        (state): PaymentEnrollmentFormState => ({
          ...state,
          showInvalidAgeModal: false,
        })
      )
      .addCase(
        validateMedicareStatus,
        (state): PaymentEnrollmentFormState => ({
          ...state,
          showMedicareModal: true,
        })
      )
      .addCase(
        passMedicareStatusValidation,
        (state): PaymentEnrollmentFormState => ({
          ...state,
          showMedicareModal: false,
          enrollmentInfo: {
            ...state.enrollmentInfo,
            termAgreement: true,
          },
          hasMedicare: false,
        })
      )
      .addCase(
        failMedicareStatusValidation,
        (state): PaymentEnrollmentFormState => ({
          ...state,
          showMedicareModal: false,
          showInvalidMedicareModal: true,
          enrollmentInfo: {
            ...state.enrollmentInfo,
            termAgreement: false,
          },
          hasMedicare: true,
        })
      )
      .addCase(
        closeIneligibleMedicareModal,
        (state): PaymentEnrollmentFormState => ({
          ...state,
          showInvalidMedicareModal: false,
        })
      );
  },
});

export const { updatePaymentEnrollmentFormState } =
  paymentEnrollmentFormSlice.actions;

const { setError, setTermsError } = paymentEnrollmentFormSlice.actions;

export function applyEnrollmentError(error: errorConstants) {
  return (dispatch: AppDispatch) => {
    if (error === errorConstants.errorTermAgreement) {
      dispatch(setTermsError(i18n.t("payment:terms:error")));
      return true;
    }

    const errorMap = {
      [errorConstants.errorActiveSubscription]: {
        email: i18n.t(
          "enrollmentForm:inputFields:email:errorMessages:activeSubscription"
        ),
      },
      [errorConstants.errorPendingDeletionRequest]: {
        email: i18n.t(
          "enrollmentForm:inputFields:email:errorMessages:pendingDeletionRequest"
        ),
      },
      [errorConstants.errorInvalidEmail]: {
        email: i18n.t("enrollmentForm:inputFields:email:errorMessages:invalid"),
      },
    };

    const errorState = errorMap[error];
    if (errorState) {
      dispatch(setError(errorState));
      return true;
    }

    return false;
  };
}

export default paymentEnrollmentFormSlice;
