import { createAction } from "@reduxjs/toolkit";

export const failNoomClinicalAgeValidation = createAction(
  "paymentEnrollmentForm/failNoomClinicalAgeValidation"
);

export const validateMedicareStatus = createAction(
  "paymentEnrollmentForm/validateMedicareStatus"
);

export const passMedicareStatusValidation = createAction(
  "paymentEnrollmentForm/passMedicareStatusValidation"
);

export const failMedicareStatusValidation = createAction(
  "paymentEnrollmentForm/failMedicareStatusValidation"
);

export const closeIneligibleMedicareModal = createAction(
  "paymentEnrollmentForm/closeIneligibleMedicareModal"
);

export const closeIneligibleAgeModal = createAction(
  "paymentEnrollmentForm/closeIneligibleAgeModal"
);
