import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type PersistentBrowserFlagsState = {
  isInMedRebootPercentageRollout?: boolean;
};

// Adding this state in order to preserve flags on device instead of preserving them just per session (browser session).
const initialState: PersistentBrowserFlagsState = {
  isInMedRebootPercentageRollout: undefined,
};

const persistentBrowserFlagsSlice = createSlice({
  name: "persistentBrowserFlags",
  initialState,
  reducers: {
    setIsInMedRebootPercentageRollout(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        isInMedRebootPercentageRollout: action.payload,
      };
    },
  },
});

export const { setIsInMedRebootPercentageRollout } =
  persistentBrowserFlagsSlice.actions;

export default persistentBrowserFlagsSlice;
