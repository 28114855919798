import Chart from "chart.js/auto";
import { getMonthDelta } from "@utils/datetime";
import {
  getImportantEventText,
  getImportantEventTextLong,
} from "src/utils/importantDate";
import Unit, { UnitType, formatWeight, convertUnits } from "src/utils/Unit";
import i18n from "@i18n";
import {
  renderGoal,
  drawLabel,
  renderMedicationVial,
} from "./drawAdditionalChartElements";
import { getTickIndexesForMonthLabelsInRange } from "./getTickIndexesForMonthLabelsInRange";
import { compassColors } from "@utils/styling";
import { SurveyAnswer } from "src/utils/redux/slices/surveyAnswers";
import {
  getWeightFromDate,
  getDateFromWeight,
} from "src/utils/weight/weightPacing";
import {
  CHART_WIDTH_TICKS,
  START_TICK,
  END_TICK,
  getTickFromNormalizedValue,
} from "src/utils/weight/ticks";
import {
  getTickFromTargetWeight,
  getVisualPoints,
} from "src/utils/weight/points";
import { trackMilestoneWeightLossPace } from "src/utils/pace/track";

const CHART_HEIGHT = 115;
const MIN_TICK_OFFSET = 15; // Min amount of ticks apart it takes for two labels to not be overlapping
const MIN_MEDICATION_TICK_OFFSET = 21;

const SEVEN_DAYS = 1000 * 60 * 60 * 24 * 7;
const FOURTEEN_DAYS = SEVEN_DAYS * 2;

export type GraphCustomization = {
  xAxisLegendColor?: string;
  xAxisBorderColor?: string;
  yAxisLegendColor?: string;
  xAxisBorderWidth?: number;
  xAxisLegendDistance?: number;
  useGradientBackground?: boolean;
  gradientStartColor?: string;
  gradientEndColor?: string;
  hasDarkBackground?: boolean;
};

export function getImportantEventSubtitle(
  startWeightKg: number,
  idealWeightKg: number,
  endDate: Date,
  surveyAnswers: SurveyAnswer
) {
  if (!surveyAnswers?.importantDateTimeISO) {
    return "";
  }
  const today = new Date();
  const importantDate = new Date(surveyAnswers.importantDateTimeISO);

  const monthsApart = getMonthDelta(today, importantDate);
  if (importantDate > today) {
    const event = getImportantEventTextLong(surveyAnswers);
    if (
      monthsApart === 0 ||
      importantDate.getTime() - today.getTime() < SEVEN_DAYS
    ) {
      return i18n.t("plans:subtitleEarlyEvent", { event });
    }
    if (
      importantDate > endDate &&
      importantDate.getTime() - endDate.getTime() > FOURTEEN_DAYS
    ) {
      return i18n.t("plans:subtitleLateEventPersonalizedPace", { event });
    }
    const importantDateWeight = getWeightFromDate(
      startWeightKg,
      idealWeightKg,
      importantDate,
      endDate
    );
    const weightText = formatWeight(startWeightKg - importantDateWeight);

    return i18n.t("plans:subtitleEvent", { weightText, event });
  }

  return "";
}

type EventNode = {
  eventType: string;
  eventTick: number;
  eventDate: Date;
  isMedicationTick: boolean;
  shouldBeHidden?: boolean;
};

// We want to ensure to communicate to the user that their medication starts as
// soon as possible, within the first 7 days. As such, we'll always treat the
// start medication date as the first date we want to render.
//
// The rest, we want to tackle in a chronological order.
function sortEvents(nodeA: EventNode, nodeB: EventNode) {
  if (
    nodeA.eventType === "startMedication" ||
    nodeA.eventDate < nodeB.eventDate
  ) {
    return -1;
  }
  if (
    nodeB.eventType === "startMedication" ||
    nodeA.eventDate > nodeB.eventDate
  ) {
    return 1;
  }
  return 0;
}

function getOffsetBetweenNodes(firstNodeTick, secondNodeTick) {
  return Math.abs(firstNodeTick - secondNodeTick);
}

function ensureEventSpacing(eventNodes: EventNode[]) {
  eventNodes.sort(sortEvents);

  if (eventNodes.length > 1) {
    for (let i = 0; i < eventNodes.length - 1; ++i) {
      const firstNode = eventNodes[i];
      const secondNode = eventNodes[i + 1];

      const nodeOffset = getOffsetBetweenNodes(
        firstNode.eventTick,
        secondNode.eventTick
      );
      const minOffset =
        firstNode.isMedicationTick || secondNode.isMedicationTick
          ? MIN_MEDICATION_TICK_OFFSET
          : MIN_TICK_OFFSET;
      const minStartTick = minOffset / 2;

      if (nodeOffset < minOffset) {
        const nudgeOffset = minOffset - nodeOffset;

        // We can only move a date to the left if we're on the first iteration,
        // otherwise we may be shifting dates onto previous ones.
        if (firstNode.eventDate <= secondNode.eventDate && i === 0) {
          // If we can accommodate the full nudge, let's do it.
          if (firstNode.eventTick - nudgeOffset > minStartTick) {
            firstNode.eventTick -= nudgeOffset;
          }
          // If not, let's split the difference
          else {
            const affordableOffset = firstNode.eventTick - minStartTick;
            firstNode.eventTick -= affordableOffset;
            secondNode.eventTick += nudgeOffset - affordableOffset;
          }
        } else {
          secondNode.eventTick += nudgeOffset;
        }
      }
    }
  }

  return eventNodes;
}

function drawWeightGraph(
  startWeightKg: number,
  idealWeightKg: number,
  idealWeightDisplay: string,
  unit: UnitType,
  targetDate: Date,
  graph: Chart,
  surveyAnswers = null,
  drawImportantDate = false,
  drawFivePercent = false,
  drawMedicationStart = false,
  hostEl: string | HTMLCanvasElement,
  questionId?: string,
  graphCustomization?: GraphCustomization
) {
  const planDuration = getMonthDelta(new Date(), targetDate);
  const planEndDate = targetDate;
  const importantEventDate = new Date(surveyAnswers?.importantDateTimeISO);

  const dataSetPoints = getVisualPoints(CHART_WIDTH_TICKS).slice(
    0,
    CHART_WIDTH_TICKS
  );

  const shouldSkipEveryOtherTick =
    (window.innerWidth < 600 && planDuration >= 7) || planDuration >= 10;

  const { labels } = getTickIndexesForMonthLabelsInRange(
    new Date(),
    planEndDate,
    CHART_WIDTH_TICKS,
    CHART_WIDTH_TICKS - 10,
    shouldSkipEveryOtherTick
  );

  const MAIN_LINE_COLOR = compassColors.stream;
  const X_AXIS_COLOR =
    graphCustomization?.xAxisBorderColor ?? compassColors.grey2;

  const currentTimestamp = new Date().getTime();

  let medicationStartTick: number;
  const medicationStartDate = new Date(currentTimestamp + SEVEN_DAYS);
  if (drawMedicationStart) {
    medicationStartTick = getTickFromNormalizedValue(
      (medicationStartDate.getTime() - currentTimestamp) /
        (planEndDate.getTime() - currentTimestamp)
    );
  }

  let shouldDrawImportantEvent = false;
  let importantEventTick: number;
  let importantEventWeight: number;
  if (drawImportantDate) {
    const canDrawImportantEvent =
      !!surveyAnswers?.importantDateTimeISO &&
      Object.prototype.toString.call(importantEventDate) === "[object Date]" &&
      // eslint-disable-next-line no-restricted-globals
      !isNaN(importantEventDate.getTime());

    if (canDrawImportantEvent) {
      importantEventTick = getTickFromNormalizedValue(
        (importantEventDate.getTime() - currentTimestamp) /
          (planEndDate.getTime() - currentTimestamp)
      );
    }

    // If the important date overlaps with the medication start badge, it's not
    // possible to render both in a meaningful way as either the user will
    // clearly spot that their important date is in the wrong place or we're
    // reporting the start of their medication incorrectly which is misleading.
    //
    // As per the decision made during the implementation of the Med Reboot, in
    // these cases we opt not to show the user's important date.
    const importantDateOverlapsWithMedicationStart =
      drawMedicationStart &&
      canDrawImportantEvent &&
      getOffsetBetweenNodes(medicationStartTick, importantEventTick) <
        MIN_MEDICATION_TICK_OFFSET;

    shouldDrawImportantEvent =
      canDrawImportantEvent && !importantDateOverlapsWithMedicationStart;
  }

  if (shouldDrawImportantEvent) {
    importantEventWeight = getWeightFromDate(
      startWeightKg,
      idealWeightKg,
      importantEventDate,
      targetDate
    );

    trackMilestoneWeightLossPace(
      "ImportantEvent",
      startWeightKg - importantEventWeight,
      importantEventDate,
      questionId
    );
  }

  let fivePercentTick: number;
  const fivePercentWeight = startWeightKg * 0.95;
  const fivePercentDate = getDateFromWeight(
    startWeightKg,
    idealWeightKg,
    fivePercentWeight,
    new Date(),
    targetDate
  );
  if (drawFivePercent) {
    fivePercentTick = getTickFromTargetWeight(
      startWeightKg,
      idealWeightKg,
      fivePercentWeight
    );
  }

  const dateNodes: EventNode[] = [
    drawMedicationStart && {
      eventType: "medicationStart",
      eventTick: medicationStartTick,
      eventDate: medicationStartDate,
      isMedicationTick: true,
    },
    shouldDrawImportantEvent && {
      eventType: "importantEvent",
      eventTick: importantEventTick,
      eventDate: importantEventDate,
      isMedicationTick: false,
    },
    drawFivePercent && {
      eventType: "fivePercent",
      eventTick: fivePercentTick,
      eventDate: fivePercentDate,
      isMedicationTick: false,
    },
  ].filter((eventNode) => eventNode);
  const sanitizedNodes = ensureEventSpacing(dateNodes);

  // Then let's update their positions should it be necessary
  if (shouldDrawImportantEvent) {
    const eventNode = sanitizedNodes.find(
      (node) => node.eventType === "importantEvent"
    );
    importantEventTick = eventNode?.eventTick ?? importantEventTick;
  }
  if (drawFivePercent) {
    const eventNode = sanitizedNodes.find(
      (node) => node.eventType === "fivePercent"
    );
    fivePercentTick = eventNode?.eventTick ?? fivePercentTick;
  }
  if (drawMedicationStart) {
    const eventNode = sanitizedNodes.find(
      (node) => node.eventType === "medicationStart"
    );
    medicationStartTick = eventNode?.eventTick ?? medicationStartTick;
  }

  const importantTicks = [
    END_TICK,
    shouldDrawImportantEvent && importantEventTick,
    drawFivePercent && fivePercentTick,
    drawMedicationStart && medicationStartTick,
  ].filter((tick) => tick);
  const goalColor = compassColors.tarocco;
  const importantEventColor = compassColors.lagoon;
  const importantEventTextColor = graphCustomization?.hasDarkBackground
    ? compassColors.sky
    : importantEventColor;
  const fivePercentColor = compassColors.blueberry;
  const fivePercentTextColor = graphCustomization?.hasDarkBackground
    ? compassColors.grey1
    : fivePercentColor;
  const medicationStartColor = compassColors.lagoon;
  const medicationStartTextColor = graphCustomization?.hasDarkBackground
    ? compassColors.sky
    : medicationStartColor;

  function getChartBackgroundGradient(ctx, chartArea) {
    if (graphCustomization?.useGradientBackground) {
      const gradient = ctx.createLinearGradient(
        0,
        chartArea.left,
        0,
        chartArea.right
      );
      gradient.addColorStop(0, graphCustomization?.gradientStartColor);
      gradient.addColorStop(1, graphCustomization?.gradientEndColor);
      return gradient;
    }
    return compassColors.offWhite;
  }

  function determineChartBackgroundColor(context) {
    const { chart } = context;
    const { ctx, chartArea } = chart;

    // This case happens on initial chart load
    if (!chartArea) return undefined;
    return getChartBackgroundGradient(ctx, chartArea);
  }

  function drawAdditionalChartElements(animationStep) {
    const { chart } = animationStep;
    renderGoal(
      chart,
      i18n.t("survey:questions:weightGraph:goal"),
      `${idealWeightDisplay}`,
      END_TICK,
      goalColor,
      { dataset: 0 }
    );

    if (shouldDrawImportantEvent) {
      drawLabel(
        chart,
        `${getImportantEventText(surveyAnswers)} (${formatWeight(
          importantEventWeight
        )})`,
        importantEventTick,
        START_TICK,
        END_TICK,
        importantEventTextColor,
        { dataset: 0 }
      );
    }

    if (drawFivePercent) {
      drawLabel(
        chart,
        i18n.t("survey:questions:weightGraph:lowerRiskFivePercent", {
          fivePercentWeight: formatWeight(fivePercentWeight),
        }),
        fivePercentTick,
        START_TICK,
        END_TICK,
        fivePercentTextColor,
        { dataset: 0 }
      );
    }

    if (drawMedicationStart) {
      renderMedicationVial(chart, medicationStartTick, { dataset: 0 });

      renderGoal(
        chart,
        i18n.t("survey:questions:weightGraph:goal"),
        `${idealWeightDisplay}`,
        END_TICK,
        goalColor,
        { dataset: 0 }
      );

      drawLabel(
        chart,
        i18n.t("survey:questions:weightGraph:startMedication"),
        medicationStartTick,
        START_TICK,
        END_TICK,
        medicationStartTextColor,
        { dataset: 0 },
        15 // Allow space for the vial graphic's width
      );
    }
  }

  const config = {
    type: "line",
    data: {
      labels,
      datasets: [
        {
          data: dataSetPoints,
          borderWidth: 4,
          borderColor: MAIN_LINE_COLOR,
          pointBorderWidth: 2,
          pointRadius(ctx) {
            if (
              ctx.dataIndex <= END_TICK &&
              importantTicks.includes(ctx.dataIndex)
            ) {
              return 10;
            }
            return 0;
          },
          pointBorderColor: "#FFF",
          pointBackgroundColor(ctx) {
            if (ctx.dataIndex === END_TICK) {
              return goalColor;
            }
            if (ctx.dataIndex === fivePercentTick) {
              return fivePercentColor;
            }
            if (ctx.dataIndex === importantEventTick) {
              return importantEventColor;
            }
            return compassColors.stream;
          },
          backgroundColor: determineChartBackgroundColor,
          fill: "1",
        },
        {
          data: new Array(CHART_WIDTH_TICKS).fill(0),
          backgroundColor: "transparent",
          borderRadius: 0,
          borderWidth: graphCustomization?.xAxisBorderWidth ?? 7,
          borderColor: X_AXIS_COLOR,
          pointRadius: 0,
        },
      ],
    },
    options: {
      clip: 20,
      layout: {
        padding: {
          right: unit === Unit.STONE ? 20 : 0,
        },
      },
      maintainAspectRatio: false,
      elements: {
        line: {
          tension: 0.2,
        },
      },
      scales: {
        x: {
          position: "bottom",
          ticks: {
            autoSkip: false,
            maxRotation: 0, // Layout becomes funky and we loose width if the ticks rotate
            maxTicksLimit: 20,
            display: true,
            textStrokeColor: "transparent",
            textStrokeWidth: 0,
            color: graphCustomization?.xAxisLegendColor ?? compassColors.grey3,
            font: {
              color: compassColors.grey3,
              size: 14,
              family: "Untitled Sans, sans-serif",
            },
          },
          grid: {
            display: false,
            drawBorder: false,
          },
        },
        y: {
          grid: {
            display: false,
            drawBorder: false,
          },
          min: -10,
          max: CHART_HEIGHT,
          ticks: {
            color: graphCustomization?.yAxisLegendColor ?? compassColors.grey3,
            font: {
              size: 14,
              family: "Untitled Sans, sans-serif",
            },
            padding: 3,
            display: true,

            callback(label, index) {
              if (index === 0) return null;
              const weightDiffPerNotch = (startWeightKg - idealWeightKg) / 5;
              const notchValue =
                idealWeightKg + (index - 1) * weightDiffPerNotch;
              if (notchValue > startWeightKg) return null;

              // Only display units in the x axis for Stone
              if (unit === Unit.STONE) {
                return formatWeight(notchValue);
              }
              return Math.round(
                convertUnits({ mainUnitValue: notchValue }, Unit.KILOGRAM, unit)
                  .mainUnitValue
              );
            },
          },
        },
      },
      animation: {
        duration: 0,
        onProgress(animationStep) {
          drawAdditionalChartElements(animationStep);
        },
        onComplete(animationStep) {
          drawAdditionalChartElements(animationStep);
        },
      },
      plugins: {
        tooltip: {
          enabled: false,
        },
        filler: {
          propagate: true,
        },
        legend: {
          display: false,
        },
      },
    },
  };

  if (graph) {
    graph.destroy();
  }

  const hostDomEl =
    typeof hostEl === "string" ? document.getElementById(hostEl) : hostEl;
  if (!hostDomEl) {
    return null;
  }

  hostDomEl.setAttribute("data-noom-graph", "personalized-pace");
  return new Chart(hostEl, config as any);
}

export default drawWeightGraph;
