import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export enum CounterOfferOutcome {
  CANCEL = "cancel",
  EXTEND_TRIAL = "extension_7_days",
  PAUSE_1_MONTH = "extension_30_days",
  PAUSE_3_MONTHS = "extension_90_days",
  PERCENT_DISCOUNT = "percent_discount",
  PAUSE_1_MONTH_DISCOUNTED = "extension_30_days_discounted_19_per_month",
  PAUSE_3_MONTHS_DISCOUNTED = "extension_90_days_discounted_19_per_month",
  APPLY_DISCOUNT = "discounted_19_per_month",
  KEEP_USING_NOOM = "keep_using_noom",
  BILLING_REMINDER = "billing_reminder",
  TRIAL_BILLING_REMINDER = "trial_billing_reminder",
  PREMIUM_DOWNGRADE = "premium_downgrade",
  PREMIUM_DOWNGRADE_DISCOUNT = "premium_downgrade_discount",
  CANCEL_MED = "cancel_med",
}

export type CounterOfferState = {
  outcome?: CounterOfferOutcome;
  rejectedOutcomes: CounterOfferOutcome[];
  processed: boolean;
  error?: {
    sourcePage: string;
    reason?: string;
  };
};

const counterOfferSlice = createSlice({
  name: "counterOffer",
  initialState: {
    processed: false,
    rejectedOutcomes: [],
  } as CounterOfferState,
  reducers: {
    updateCounterOfferOutcome: (
      state,
      action: PayloadAction<CounterOfferOutcome>
    ) => {
      return {
        ...state,
        outcome: action.payload,
        processed: false,
      };
    },
    updateCounterOffer: (
      state,
      action: PayloadAction<Partial<CounterOfferState>>
    ) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { updateCounterOfferOutcome, updateCounterOffer } =
  counterOfferSlice.actions;

export default counterOfferSlice;
