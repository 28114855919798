import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import i18n from "@i18n";
import styled from "@emotion/styled";

import {
  calculateRecommendedPlan,
  getWeightInformation,
  getRecommendedPlan,
} from "@utils/calculateGoals";
import { getNoomPlanIdFromKey, savePlanToBraze } from "@utils/plans";
import { trackEvent } from "@utils/api/tracker";
import {
  getMonthDelta,
  LONG_DATE_FORMAT,
  MONTH_LONG_DAY_FORMAT,
} from "@utils/datetime";
import { isIntlMantle } from "@utils/userSegment";
import { getIndexFromGraphId } from "@utils/pace/graphIndex";
import { addSurveyAnswers } from "@utils/redux/slices/surveyAnswers";
import { addMainSurveyAnswers } from "@utils/redux/slices/userData";
import { hasPromoCodeAvailable } from "@utils/promo";

import StickyCTAChoosePlan from "src/choosePlan/components/StickyCTAChoosePlan";
import { HeadlineHeader } from "src/components/header/HeadlineHeader";
import { BrightSpots } from "src/components/weight-graph/BrightSpots";
import { WeightGraph } from "src/components/survey/weightGraph";
import { MainColumn } from "src/components/core/BodyLayout";
import { WeightGraphAfterContent } from "src/components/weight-graph/WeightGraphTitle";
import { PrimaryFocusBlock } from "src/design-system/components/PrimaryFocusBlock/PrimaryFocusBlock";
import { breakpointMobileExtended } from "src/design-system/styles/breakpoints";
import { useOnce } from "src/hooks/lifecycle";
import { useAppDispatch } from "src/hooks/redux";
import { useSurveyAnswers } from "src/hooks/survey/answers";
import { usePageSet } from "src/hooks/pageSet";
import { captureException } from "src/utils/error";
import { areMainSurveyAnswersValid } from "src/utils/mainSurvey";
import { getLocale } from "src/utils/meristemContext";
import { formatWeight } from "src/utils/Unit";
import { getPace, getPersonalizedTargetDate } from "src/utils/pace";
import goto from "src/pageDefinitions/goto";
import { CoreReduxState } from "src/utils/redux/store";
import { setRecommendedPlanById } from "src/utils/redux/slices/recommendedPlan";
import { Plan } from "src/utils/redux/slices/plans";
import { trackWeightGraph } from "src/utils/pace/track";
import { isNursing } from "src/utils/pace/planTimeAdjustmentNursing";

import { PlansCTA } from "./PlansCTA";
import {
  isEligibleForNewEmailFlow,
  isEligibleForPreCheckoutFreeMWCPOffer,
} from "src/utils/userSegment/features";
import { FreeMWCPModalEnabler } from "src/components/modal/free-mwcp-modal/FreeMWCPModalEnabler";

declare module "@utils/redux/slices/surveyAnswers" {
  interface SurveyAnswersState {
    choosePlanPace?: number;
  }
}

const Section = styled.section`
  position: relative;
  box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.7);

  @media ${breakpointMobileExtended} {
    box-shadow: none;
  }
`;

const getTargetDateString = (targetDate: Date) => {
  if (getMonthDelta(new Date(), targetDate) >= 12) {
    return targetDate.toLocaleDateString(getLocale(), LONG_DATE_FORMAT);
  }
  return targetDate.toLocaleDateString(getLocale(), MONTH_LONG_DAY_FORMAT);
};

export function calculateTargetMonthFromDate(targetDate: Date) {
  const targetMonthNameFull = targetDate.toLocaleDateString(getLocale(), {
    month: "long",
  });
  const targetMonthNameAbbr = targetDate.toLocaleDateString(getLocale(), {
    month: "short",
  });

  return {
    targetMonthNameFull,
    targetMonthNameAbbr,
  };
}

function renderPromoCode() {
  if (hasPromoCodeAvailable()) {
    const promoCode = new URLSearchParams(window.location.search).get(
      "display_promo"
    );
    return i18n.t("plans:promoCode", {
      promoCode,
    });
  }

  return null;
}

export function Plans() {
  const surveyAnswers = useSurveyAnswers();
  const {
    trial: { trialFeeWaived },
  } = useSelector((state: CoreReduxState) => ({
    trial: state.trial,
    surveyNameSpace: state.surveyNameSpace,
  }));

  const dispatch = useAppDispatch();

  const { gotoNextPage } = usePageSet();

  const waypointRef = useRef<HTMLElement>();
  const planLoaderRef = useRef<
    Promise<{
      recommendedPlan: Partial<Plan>;
      planDuration: number;
    }>
  >();

  const pace = getPace(getIndexFromGraphId("choosePlan"));

  useOnce(() => {
    const ans = { choosePlanPace: pace };
    dispatch(addSurveyAnswers(ans));
  });

  useEffect(() => {
    const { areValid, invalidProperties } =
      areMainSurveyAnswersValid(surveyAnswers);

    if (!areValid) {
      trackEvent("MissingBasicDetails", { invalidProperties });
      goto.bail("landing", [], "MissingBasicDetails");
      return;
    }

    // Get the recommended plan and calculate the recommended plan duration.
    let { recommendedPlanDuration, currentBMI, targetBMI } =
      calculateRecommendedPlan(surveyAnswers);

    planLoaderRef.current = getRecommendedPlan({
      recommendedPlanDuration,
      isMedPlan: false,
    });
    planLoaderRef.current.then(({ recommendedPlan, planDuration }) => {
      recommendedPlanDuration = planDuration;

      trackWeightGraph("choosePlan", surveyAnswers);

      const targetDate = getPersonalizedTargetDate(
        getIndexFromGraphId("choosePlan")
      );
      const { targetMonthNameFull } = calculateTargetMonthFromDate(targetDate);

      // Send the plan duration to mixpanel after it gets set
      trackEvent("SetPlanDuration", {
        planDuration: recommendedPlanDuration,
      });

      savePlanToBraze({
        currentBMI,
        targetBMI,
        targetMonthNameFull,
        recommendedPlan,
      });

      // Add signal in case we display NaN for unknown reasons.
      const dateNum = targetDate.getDate();
      if (isNaN(dateNum)) {
        captureException(new Error(`Invalid target date: ${targetDate}`));
      }
    });
  }, [surveyAnswers]);

  async function handleCTAClick(eventName: string) {
    trackEvent(eventName);
    const { recommendedPlan, planDuration } = await planLoaderRef.current;
    const defaultPlanNoomPlanId = getNoomPlanIdFromKey(`${planDuration ?? 4}`);

    const purchaseNoomPlanId =
      recommendedPlan?.noom_plan_id || defaultPlanNoomPlanId;
    const ans = { choosePlanPace: pace };
    await dispatch(addMainSurveyAnswers(ans));
    await dispatch(setRecommendedPlanById(purchaseNoomPlanId));
    gotoNextPage();
  }

  const { targetWeightKg } = getWeightInformation(surveyAnswers);

  const targetDate = getPersonalizedTargetDate(
    getIndexFromGraphId("choosePlan")
  );

  const { targetMonthNameAbbr } = calculateTargetMonthFromDate(targetDate);

  const targetDateString = getTargetDateString(targetDate);
  const targetWeightText = formatWeight(targetWeightKg, surveyAnswers);

  const headlineSticky = i18n.t("plans:idealWeightBySticky", {
    idealWeight: targetWeightText,
    monthName: targetMonthNameAbbr,
  });

  return (
    <>
      {isEligibleForPreCheckoutFreeMWCPOffer() && <FreeMWCPModalEnabler />}
      <Section id="plans" ref={waypointRef}>
        <StickyCTAChoosePlan
          headline={headlineSticky}
          onClick={() => handleCTAClick("ClickedChoosePlanStickyCTA")}
          waypointRef={waypointRef}
        />
        <HeadlineHeader>{i18n.t("plans:headline")}</HeadlineHeader>

        <PrimaryFocusBlock deferFocus>
          <MainColumn maxWidth={600}>
            <WeightGraph
              questionId="choosePlan"
              highlight={renderPromoCode()}
              ariaHidden
            />
            <WeightGraphAfterContent css={{ marginBottom: "0px" }}>
              <p>
                {i18n.t("plans:healthyPaceWithTargetDate1", {
                  targetDate: targetDateString,
                })}
              </p>
              {isNursing(surveyAnswers) && (
                <p>{i18n.t("plans:nursingPaceChange")}</p>
              )}
              <p>
                {isEligibleForNewEmailFlow()
                  ? i18n.t("plans:dialedIn")
                  : i18n.t("plans:healthyPaceWithTargetDate2")}
              </p>

              {isIntlMantle() && <BrightSpots stage="CHOOSE_PLAN" />}
            </WeightGraphAfterContent>
          </MainColumn>
        </PrimaryFocusBlock>
        <MainColumn>
          <WeightGraphAfterContent css={{ marginTop: "0px" }}>
            <PlansCTA
              onClick={() => handleCTAClick("ClickedChoosePlanCTA")}
              trialFeeWaived={trialFeeWaived}
            />
          </WeightGraphAfterContent>
        </MainColumn>
      </Section>
    </>
  );
}
