import { NoomError } from "../error/NoomError";
import React from "react";
import i18n from "../../locales";
import { useCheckout } from "src/hooks/redux";
import styled from "@emotion/styled";
import { ErrorMessage } from "src/components/error/ErrorMessage";
import { trackEvent } from "../api/tracker";

export enum errorConstants {
  errorUnknown = "ERROR_UNKNOWN",
  errorPaymentClientRequest = "ERROR_PAYMENT_CLIENT_REQUEST",
  errorProgramSetup = "ERROR_PROGRAM_SETUP",
  errorActiveSubscription = "ERROR_ACTIVE_SUBSCRIPTION",
  errorPendingDeletionRequest = "ERROR_PENDING_DELETION_REQUEST",
  errorBillingAddressEmpty = "ERROR_BILLING_FIELDS_EMPTY",
  errorEnrollmentFieldsEmpty = "ERROR_ENROLLMENT_FIELDS_EMPTY",
  errorEnrollmentFieldsError = "errorEnrollmentFieldError", // client only
  errorPaymentProcessor = "ERROR_PAYMENT_PROCESSOR",
  errorInvalidCreditCard = "ERROR_INVALID_CREDIT_CARD",
  errorInvalidEmail = "ERROR_INVALID_EMAIL",
  errorPaymentMethod = "ERROR_PAYMENT_METHOD",
  errorSalesTaxLocation = "ERROR_SALES_TAX_LOCATION",
  errorTermAgreement = "ERROR_ACCEPT_TERMS_AGREEMENT",
  errorTransactionCreation = "ERROR_TRANSACTION_CREATION",
  errorUnavailableCountry = "ERROR_UNAVAILABLE_COUNTRY",
  paypalPopupClosed = "PAYPAL_POPUP_CLOSED",
  paypalAccountTokenizationFailed = "PAYPAL_ACCOUNT_TOKENIZATION_FAILED",
  paypalFlowFailed = "PAYPAL_FLOW_FAILED",
  hostedFieldsFieldsEmpty = "HOSTED_FIELDS_FIELDS_EMPTY",
  hostedFieldsFieldsInvalid = "HOSTED_FIELDS_FIELDS_INVALID",
  hostedFieldsTokenizationFailOnDuplicate = "HOSTED_FIELDS_TOKENIZATION_FAIL_ON_DUPLICATE",
  hostedFieldsTokenizationCVVVerificationFailed = "HOSTED_FIELDS_TOKENIZATION_CVV_VERIFICATION_FAILED",
  hostedFieldsFailedTokenization = "HOSTED_FIELDS_FAILED_TOKENIZATION",
  hostedFieldsTokenizationNetworkError = "HOSTED_FIELDS_TOKENIZATION_NETWORK_ERROR",
  walletPopupClosed = "WALLET_POPUP_CLOSED",
  noomClinicalIneligibleAge = "NOOM_CLINICAL_INELIGIBLE_AGE",
  noomClinicalUnavailableInCountry = "NOOM_CLINICAL_UNAVAILABLE_IN_COUNTRY",
  noomClinicalUnavailableInState = "NOOM_CLINICAL_UNAVAILABLE_IN_STATE",
  physicalAddressValidationFailed = "PHYSICAL_ADDRESS_VALIDATION_FAILED",
  errorPaypalAccount = "ERROR_PAYPAL_ACCOUNT",
  errorHasMedicare = "ERROR_HAS_MEDICARE",
}

export const paymentErrorConstants = [
  errorConstants.errorUnknown,
  errorConstants.errorPaymentClientRequest,
  errorConstants.errorBillingAddressEmpty,
  errorConstants.errorPaymentProcessor,
  errorConstants.errorInvalidCreditCard,
  errorConstants.errorPaymentMethod,
  errorConstants.errorSalesTaxLocation,
  errorConstants.errorTransactionCreation,
  errorConstants.errorUnavailableCountry,
  errorConstants.paypalPopupClosed,
  errorConstants.paypalAccountTokenizationFailed,
  errorConstants.paypalFlowFailed,
  errorConstants.hostedFieldsFieldsEmpty,
  errorConstants.hostedFieldsFieldsInvalid,
  errorConstants.hostedFieldsTokenizationFailOnDuplicate,
  errorConstants.hostedFieldsTokenizationCVVVerificationFailed,
  errorConstants.hostedFieldsFailedTokenization,
  errorConstants.hostedFieldsTokenizationNetworkError,
  errorConstants.walletPopupClosed,
];

export const enrollmentErrorConstants = [
  errorConstants.errorActiveSubscription,
  errorConstants.errorEnrollmentFieldsEmpty,
  errorConstants.errorEnrollmentFieldsError,
  errorConstants.errorInvalidEmail,
  errorConstants.errorHasMedicare,
];

export class ValidationError extends NoomError {
  constructor(public errors: errorConstants[], public invalidFields: string[]) {
    super("Form validation failed");
  }

  override trackMetric() {
    trackEvent("OnCheckoutPaymentError", {
      errors: this.errors,
      invalidFields: this.invalidFields,
    });
    return true;
  }
}

/**
 * Get the language specific error message.
 */
export function getPurchaseErrorMessage(error: any) {
  const errorType = typeof error === "object" ? error.code : error;

  if (errorType === errorConstants.hostedFieldsFieldsEmpty && error.details) {
    const errorMessage: Record<string, string> = {};

    error.details.invalidFieldKeys.forEach((fieldKey: string) => {
      errorMessage[fieldKey] = i18n.t(`payment:validation:${fieldKey}`);
    });

    return errorMessage;
  }

  return {
    errorMessage: i18n.t([
      `payment:errors:${errorType}`,
      `payment:errors:${errorConstants.errorUnknown}`,
    ]),
  };
}

const PurchaseErrorItem = styled.span`
  display: block;
  margin-bottom: 8px;
`;

export function PurchaseErrorMessage() {
  const { purchaseErrorCode } = useCheckout();

  if (!purchaseErrorCode) {
    return null;
  }
  const purchaseErrorMessage = getPurchaseErrorMessage(purchaseErrorCode);

  return (
    <ErrorMessage announceError={false}>
      {Object.keys(purchaseErrorMessage).map((error) => (
        <PurchaseErrorItem key={error}>
          {purchaseErrorMessage[error]}
        </PurchaseErrorItem>
      ))}
    </ErrorMessage>
  );
}
