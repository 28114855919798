import Cookies from "js-cookie";
import { apiCreateVisit, apiUpdateVisit } from "./VisitApi";
import { typedKeys } from "../typeWrappers";
import { isInApp } from "../userSegment";
import {
  getCountryCode,
  getLanguage,
  getRequestMetadata,
  getRouteId,
  getSubdivision,
} from "../meristemContext";
import { updateURLParams } from "../urlParams";
import { registerMonitorProvider } from "../monitoring/prop-registry";
import { USER_IS_BOT } from "../botDetector";

interface TrackingParams {
  initialUrl: string;
  referrer?: string;

  sp?: string;

  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_content?: string;
  utm_term?: string;

  [key: string]: string;
}

const NOOM_DOMAINS_MATCH = [
  "^http://localhost:8080",
  "^https://.*.noom.com",
  "^https://buyflow-lambda.(dev|test).wsli.dev",
  "^https://buyflow-web-assets.test.wsli.dev",
]
  .map((domain) => `(${domain})`)
  .reduce((val, acc) => `${acc}|${val}`);

const REFERRER_TO_UTM_SOURCE_MAPPING = {
  none: "$direct",
  ".*google\\..*": "google_organic",
  ".*facebook\\..*": "facebook_organic",
  ".*instagram\\..*": "instagram_organic",
  ".*pinterest\\..*": "pinterest_organic",
  ".*bing\\..*": "bing_organic",
  ".*twitter\\..*": "twitter_organic",
  ".*noom\\..*": "noom_internal",
};

const URL_PARAMS_SESSION_KEY = "urlParams";
const EXTERNAL_URL_PARAM_COOKIE_KEY = "external_url_params";

enum OPERATION_ENUM {
  CREATE = "CREATE",
  UPDATE = "UPDATE",
  NOOP = "NOOP",
}

export function isReferrerFromNoom(referrer = document.referrer) {
  return !!referrer?.match(NOOM_DOMAINS_MATCH);
}

function isUserExternal() {
  return isReferrerFromNoom() && Cookies.get(EXTERNAL_URL_PARAM_COOKIE_KEY);
}

function formatMeristemExperimentStateVisitData() {
  const experimentProps = Object.entries(
    getRequestMetadata().meristemTrackingProperties
  ).reduce((acc, [key, value]) => {
    if (key.startsWith("Meristem ") || key.endsWith(" SHA")) {
      acc[key] = value;
    }
    return acc;
  }, {});

  if (Object.keys(experimentProps).length) {
    return { meristem_experiment: experimentProps };
  }
  return {};
}

export class VisitTracker {
  operation: OPERATION_ENUM;

  urlParams: TrackingParams;
  private pendingData: JsonObject;

  constructor() {
    this.operation = OPERATION_ENUM.CREATE;

    const urlParamsValue = sessionStorage.getItem(URL_PARAMS_SESSION_KEY);
    this.urlParams = urlParamsValue ? JSON.parse(urlParamsValue) : undefined;
  }

  /**
   * Method used to initialize the Visit Tracker once we have the sessionCookieDomain.
   */
  init(sessionCookieDomain: string) {
    const urlParamsSet = !!this.urlParams;

    let { URL: url } = document;
    let referrer = document.referrer || "none";
    let extractNewUrlParams = true;

    if (urlParamsSet) {
      if (isReferrerFromNoom()) {
        // Case where user goes from LP -> payment -> add ons, etc.
        // Make sure we continue using stored url params.
        this.operation = OPERATION_ENUM.NOOP;

        // Only update if we have explicitly passed utm
        extractNewUrlParams = false;
      } else {
        // Case where user has entered our buyflow, goes out and then returns again
        // in the same session. Do an update to catch the case where they might be
        // coming back with new url parameters (e.g. clicked different facebook ad).
        // Backend will compare and create a new visit only if necessary.
        this.operation = OPERATION_ENUM.UPDATE;
      }
    } else if (isUserExternal()) {
      // Case where visit was created externally and stored in the cookie.
      // Extract visit info from the cookie instead of the document url.
      this.operation = OPERATION_ENUM.NOOP;
      [url, referrer] =
        VisitTracker.extractFromExternalParams(sessionCookieDomain);
    } else if (isInApp()) {
      // Case where user is in the in app buyflow. We already created a visit manually
      // in the backend. Just make sure to extract visits from the document url.
      this.operation = OPERATION_ENUM.NOOP;
    }

    // Extract and store new url parameters if needed.
    if (extractNewUrlParams) {
      this.extractUrlParams(url, referrer);
    }
  }

  createOrUpdateVisit() {
    // Don't create a visit if we are a bot.
    if (USER_IS_BOT) {
      return;
    }

    const additionalParams = {
      ...formatMeristemExperimentStateVisitData(),
      ...this.pendingData,
    };

    if (this.operation === OPERATION_ENUM.CREATE) {
      updateVisit(true, this.urlParams, additionalParams);
    } else if (
      this.operation === OPERATION_ENUM.UPDATE ||
      Object.keys(additionalParams).length !== 0
    ) {
      updateVisit(false, this.urlParams, additionalParams);
    }

    this.pendingData = undefined;
    this.operation = OPERATION_ENUM.NOOP;
  }

  /**
   * Updates the current visit session when new URL parameters are added.
   * This can
   */
  updateTrackingParams(
    urlParams: Omit<TrackingParams, "initialUrl" | "referrer">
  ) {
    updateURLParams(urlParams);
    const { URL: url } = document;

    this.extractUrlParams(url, this.urlParams?.referrer);

    this.operation = OPERATION_ENUM.UPDATE;
    this.createOrUpdateVisit();
  }

  getTrackingParams() {
    return this.urlParams;
  }

  static extractFromExternalParams(sessionCookieDomain: string) {
    const externalUrlParams = JSON.parse(
      decodeURIComponent(Cookies.get(EXTERNAL_URL_PARAM_COOKIE_KEY))
    );
    Cookies.remove(EXTERNAL_URL_PARAM_COOKIE_KEY, {
      path: "/",
      domain: sessionCookieDomain,
    });
    return [externalUrlParams.url, externalUrlParams.referrer];
  }

  private extractUrlParams(rawUrl: string, referrer: string) {
    const url = new URL(rawUrl);
    const searchParams = new URLSearchParams(url.search);
    const formattedUrlParams: TrackingParams = {
      initialUrl: `${url}`,
      referrer,
      utm_source: extractUtmSource(searchParams, referrer, this.urlParams),
    };
    searchParams.forEach((value, key) => {
      if (!formattedUrlParams[key]) {
        formattedUrlParams[key] = value;
      }
    });

    this.urlParams = formattedUrlParams;

    sessionStorage.setItem(
      URL_PARAMS_SESSION_KEY,
      JSON.stringify(this.urlParams)
    );

    return formattedUrlParams;
  }
}

export function extractUtmSource(
  searchParams: URLSearchParams,
  referrer: string,
  priorTrackingParameters?: TrackingParams
) {
  const utmSourceFromURL = searchParams.get("utm_source");
  if (utmSourceFromURL) {
    return utmSourceFromURL;
  }

  if (priorTrackingParameters?.utm_source) {
    return priorTrackingParameters.utm_source;
  }

  if (referrer) {
    return (
      REFERRER_TO_UTM_SOURCE_MAPPING[
        typedKeys(REFERRER_TO_UTM_SOURCE_MAPPING).find((key) =>
          referrer.match(key)
        )
      ] || "other"
    );
  }
  return "$direct";
}

function updateVisit(creating: boolean, urlParams: JsonObject, rawData = {}) {
  const requestBody = {
    currentUrl: urlParams.initialUrl || document.URL,
    urlParams: JSON.stringify(urlParams),
    rawData: {
      country: getCountryCode(),
      subdivision: getSubdivision(),
      language: getLanguage(),
      route_id: getRouteId(),
      ...rawData,
    },
  };
  if (creating) {
    apiCreateVisit(requestBody);
  } else {
    apiUpdateVisit(requestBody);
  }
}

const visitTracker = new VisitTracker();

export function getTrackingParams() {
  return visitTracker.getTrackingParams();
}

registerMonitorProvider("monitoring", () => {
  // Duplicates buyflow worker, but this data will be more accurate based on the
  // session state for the user.
  const urlParams = getTrackingParams();

  const utmParamsMapping = {
    utm_source: "UTM Source - Current",
    utm_medium: "UTM Medium - Current",
    utm_campaign: "UTM Campaign - Current",
    utm_content: "UTM Content - Current",
    utm_term: "UTM Term - Current",
    referrer: "Referrer URL",
  };
  const mixpanelProperties: JsonObject = {};
  Object.keys(urlParams || {}).forEach((paramKey) => {
    if (paramKey in utmParamsMapping) {
      const mappingKey = paramKey as keyof typeof utmParamsMapping;
      mixpanelProperties[utmParamsMapping[mappingKey]] = urlParams[paramKey];
    } else {
      mixpanelProperties[paramKey] = urlParams[paramKey];
    }
  });
  return mixpanelProperties;
});

export default visitTracker;
